const refToChannel = (referrer: string, isParent: boolean) => {
    switch (referrer) {
      case "tcb":
        return "corpWelTEA";
      case "tso":
        return "corpWelTSO";
      case "tsocheckout":
        return "corpWelTSO";
      case "tsoprntquote":
        return "corpWelTSO";
      case "sso":
        return "corpWelSSO";
      case "ssocheckout":
        return "corpWelSSO";
      case "ssocoreg":
        return "corpWelSSO";
      case "ssocosignin":
        return "corpWelSSO";
      case "ofesso":
        return "corpWelOFE";
      case "pcs":
        return "corpWelPAR";
      case "tcs":
        return "corpWelTEA";
      case "beta":
        return "corpWelGen";
      case "classmags":
        return "corpWelGen";
      case "lah":
        return "corpWelGen";
      case "rif":
        return "corpWelGen";
      case "cos":
        return "corpWelGen";
      case "face":
        return "corpWelGen";
      case "lp":
        return "corpWelGen";
      case "nys":
        return "corpWelGen";
      case "usor":
        return "corpWelUSoR";
      case "storyvoice":
        return "corpWelStoryvoice";
      case 'sdc':
        return "corpWelGen";
      case "ror":
        return isParent
          ? "corpWelPAR"
          : "corpWelTEA";
      default:
        return isParent
          ? "corpWelGen"
          : "corpWelTEA";
    }
  };
  
  export default refToChannel;
  