const isCustomReg = (ref: string) => {
    switch(ref){
        case 'clubs':
        case 'sdm':
            return true;
        default:
            return false;
    }
}

export default isCustomReg;