const welcomeConfig = (referrer: string) => {
    
    const welcomeTitle = ((referrer) => {
        switch (referrer) {
            case 'ewallet':
                return "Welcome to Scholastic Book Fairs!";
            default:
                return "Welcome to Scholastic!"
        }
    })

    const welcomeMsg = ((referrer) => {
        switch (referrer) {
            case 'ewallet':
                return "Get started with your cash-free, book fair spending account for students.";
            default:
                return "You have completed your registration."
        }
    })

    const welcomeBttn = ((referrer) => {
        switch (referrer) {
            case 'ewallet':
                return "Create eWallet";
            default:
                return "Continue"
        }
    })
    return [welcomeTitle(referrer), welcomeMsg(referrer), welcomeBttn(referrer)];
}

export default welcomeConfig;