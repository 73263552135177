const isPartner = (ref: string) => {
    switch(ref){
        case 'face':
        case 'lp':
        case 'nys':
        case 'cos':
        case 'rif':
        case 'ror':
            return true;
        default:
            return false;
    }
}

export default isPartner;