const validate = ((input: string, validators: string[]) => {
  let err = '';
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9`~!@#$%^&*()_+=\-{}|\x22:<>?\/.,;\'\[\]\\]+){7,}$/;
  const passwordNumLetterRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9`~!@#$%^&*()_+=\-{}|\x22:<>?\/.,;\'\[\]\\]+)$/;
  const passwordSpecialRegex = /^[^<>]*$/;
  const atLeastOneCharRegex = /[\S]/;
  const atLeastOneUpperOneLower = /(?=.*[a-z])(?=.*[A-Z])/;
  const unicodeInputRegex = /^[a-zA-Z0-9 -]+$/; //a-z, 0-9, space, and hyphen only

  validators.some((validator: string) => {
    switch(validator){
      case 'required':
        input.length > 0 ? err = '' : err = 'This field is required.';
        break;
      case 'firstNameReq':
        input.length > 0 ? err = '' : err = 'Please enter a first name.';
        break;
      case 'lastNameReq':
        input.length > 0 ? err = '' : err = 'Please enter a last name.';
        break;
      case 'unicodeCheck':
        unicodeInputRegex.test(input) ? err = '' : err = 'Alphanumeric, spaces and hyphens are the only characters allowed';
        break;
      case 'nameMinReq':
        input.length > 1 && input.trim().length != 0  ? err = '' : err = 'Your name must include at least two letters.';
        break; 
      case 'childNameMinReq':
        input.length > 1 ? err = '' : err = 'Your child\'s name must include at least two letters.';
        break;
      case 'zipcodeMinReq':
        input.length > 4 ? err = '' : err = 'Please enter a valid five-digit U.S. ZIP code.';
        break;
      case 'emailFormat':
        emailRegex.test(input) ? err = '' : err = 'Please enter a properly formatted email address.';
        break;
      case 'password':
        passwordRegex.test(input) ? err = '' : err = 'Please enter a valid password.'
        break;
      case 'passwordMin':
        input.length >= 7 ? err = '' : err = 'Please enter a valid password.'
        break;
      case 'passwordNumLetter':
        passwordNumLetterRegex.test(input) ? err = '' : err = 'Please enter a valid password.'
        break;
      case 'oneUpperOneLower':
        atLeastOneUpperOneLower.test(input) ? err = '' : err = 'Please enter a valid password.'
        break;
      case 'passwordSpecial':
        passwordSpecialRegex.test(input) && atLeastOneCharRegex.test(input) ? err = '' : err = 'Please enter a valid password.'
        break;      
      case 'phoneMinReq':
        input.length > 9 ? err = '' : err = 'Your phone number must be at least ten numbers'
        break;
      case 'addressMinReq':
        input.length > 2 ? err = '' : err = 'Your address must include at least three letters.';
        break;
      case 'cityMinReq':
        input.length > 2 ? err = '' : err = 'Your city must include at least three letters.';
        break;
      case 'schoolMinReq':
        input.length > 2 ? err = '' : err = 'School name must include at least three letters.';
        break;
      default:
        err = '';
    }
    return err;
  });

  return err;
});

export default validate;