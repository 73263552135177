import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useVerifyToken = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('GET');

  const verifyToken = async (urlToken: string) => {
    return await makeApiRequest(`${apiBase}/api/verify-token?token=${urlToken}`, {});
  };

  return [verifyToken];
}

export default useVerifyToken;