import React from 'react';
import styles from './footer.module.scss';

const Footer = () => {
  const scholasticPrivacyLink: string = 'http://www.scholastic.com/privacy.htm';
  const scholasticTermsLink: string = 'http://www.scholastic.com/terms.htm';
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className={styles.footer} role="contentinfo">
      <div className={styles.footerLinks}>
        <div className={styles.footerLink}>
          <a className={styles.privacyLink} href={scholasticPrivacyLink} target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>
          <a className={styles.termsOfUse} href={scholasticTermsLink} target="_blank" rel="noopener noreferrer">Terms of Use</a>
        </div>
      </div>
      <div className={styles.copyright}>
        TM ® & © {year} Scholastic Inc. All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer;
