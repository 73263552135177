import schlAuthJWT from "../interfaces/schlAuthJWT";

const decodeJWT = (encodedJWT: any) => {
    let token: schlAuthJWT = <schlAuthJWT>{};
    token.header = JSON.parse(window.atob(encodedJWT.split('.')[0]));
    token.payload = JSON.parse(window.atob(encodedJWT.split('.')[1]));
    // TODO - remove logs once testing is done
    console.log('Decoded SCHL_AUTH');
    console.log(token);
    return token;
}

export default decodeJWT;