// used for tso, tsocheckout, tsoprntquote, tcs, beta, corphome, myschl
const tsoCSR = "1-800-560-6816";
// used for sso, ssocheckout, ssocoreg, ssocosignin, pcs
const ssoCSR = "1-800-770-4662";
const ofessoCSR = "1-800-770-4662";
// used for cptk, vms
const cptkCSR = "1-800-799-7323";
// used for classmags, lah
const lahCSR = "1-800-631-1586";

const baselineMsg = ((errorType: string, referrer: string) => {
    switch(errorType){
        case 'emailFormatError':
            return "Please enter a properly formatted email address.";
        case 'emailRequiredError':
            return "This field is required";
        case 'emailApiError':
            return "We don't recognize this email address, please try again.";
        case 'emailInactive':
            return mapMsgWithCSR("The account associated with this email address may not be active. Please contact Customer Service at", referrer);
        case 'passwordApiError':
            return "This password is incorrect. Please try again or click \"Forgot password\" below to reset.";
        case 'passwordPolicyError':
                return "The password doesn't meet our security requirements. Please reset your password by clicking on 'Forgot Password'";
        case 'passwordLocked':
            return mapMsgWithCSR("Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes, or else please contact Customer Service at", referrer);
        case 'passwordEducatorError':
            return mapEducatorMsg(referrer);
        case 'forgotPasswordEmailFormatError':
            return "Please enter a properly formatted email address.";
        case 'forgotPasswordEmailApiError':
            return "We don’t recognize this email address, please try again.";
        case 'forgotPasswordEmailInactive':
            return mapMsgWithCSR("The account associated with this email address may not be active. Please contact Customer Service at", referrer);
        case 'forgotPasswordEmailLocked':
            return mapMsgWithCSR("Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes, or else please contact Customer Service at", referrer);
        case 'forgotYourEmailNameRequiredError':
            return "This field is required.";
        case 'forgotYourEmailNameMinError':
            return "Your name must include at least two letters.";
        case 'forgotYourEmailZipCodeRequiredError':
            return "This field is required.";
        case 'forgotYourEmailZipCodeMinError':
            return "Please enter a valid five-digit U.S ZIP Code";
        case 'resetPasswordConfirmMatchError':
            return "Your password did not match. Try again.";
        case 'resetPasswordTokenError':
            return "The Link has expired, please click on Resend Link to have another email sent to you.";
        case 'unavailableError':
            return mapMsgWithCSR("Something went wrong, please try again later or contact", referrer);
        case 'parseTokenError':
            return 'There was an error processing your request, please enter your email again and click continue';
        default:
            console.log('Check call to baselineMsg for any slipped error case');
            return "";
    }
});

const mapMsgWithCSR = ((errorMessage: string, referrer: string) => {
    switch(referrer){
        case 'tso':
        case 'tsocheckout':
        case 'tsoprntquote':
        case 'tcs':
        case 'beta':
        case 'myschl':
        case 'corphome':
        case 'tcb':
        case 'bw':
            return `${errorMessage} ${tsoCSR}`;
        case 'sso':
        case 'ssocheckout':
        case 'ssocoreg':
        case 'ssocosignin':
        case 'usor':
        case 'pcs':
            return `${errorMessage} ${ssoCSR}`;
        case 'ofesso':
            return `${errorMessage} ${ofessoCSR}`;
        case 'cptk':
        case 'vms':
        case 'ewallet':
            return `${errorMessage} ${cptkCSR}`;
        case 'classmags':
        case 'lah':
            return `${errorMessage} ${lahCSR}`;
        default:
            return `${errorMessage} ${tsoCSR}`;
    }
})

const mapEducatorMsg = ((referrer: string) => {
    switch(referrer){
        case 'tso':
        case 'tsocheckout':
        case 'tsoprntquote':
        case 'beta':
            return "The Teacher Store is for educators only. Please sign in using your educator account. If you do not have an educator account, you can update your role on the My Scholastic Profile page.";
        case 'tcs':
        case 'classmags':
            return "This site is for Educators only. Please sign in as an Educator or Register as an Educator to continue";
        default:
            return "This site is for Educators only. Please sign in as an Educator or Register as an Educator to continue";
    }
})

export default baselineMsg;