export const usorRedirectConfig: any = {
    dev: {
        usor: "https://myaccounts-qa.scholastic.com/my-scholastic/register/basic-information.html?ref=usor&sourceId=corpWelUSoR"
    },
    qa: {
        usor: "https://myaccounts-qa.scholastic.com/my-scholastic/register/basic-information.html?ref=usor&sourceId=corpWelUSoR"
    },
    stage: {
        usor: "https://myaccounts-stage.scholastic.com/my-scholastic/register/basic-information.html?ref=usor&sourceId=corpWelUSoR"
    },
    perf: {
        usor: "https://myaccounts-perf.scholastic.com/my-scholastic/register/basic-information.html?ref=usor&sourceId=corpWelUSoR"
    },
    prod: {
        usor: "https://account.scholastic.com/my-scholastic/register/basic-information.html?ref=usor&sourceId=corpWelUSoR"
    }
}

export default usorRedirectConfig;