import getCookieValue from '../utils/get-cookie-value';
import deleteCookie from './delete-cookie';

// Legacy scholastic cookie
const SPS_BUS: string = 'SPS_BUS';
const SPS_SESSION: string = 'SPS_SESSION';
const SPS_SESSION_SECURE: string = 'SPS_SESSION_SECURE';
const SPS_TSP: string = 'SPS_TSP';
const SPS_TSP_SECURE: string = 'SPS_TSP_SECURE';
const SPS_UD: string = 'SPS_UD';
const XUS_EI: string = 'XUS_EI';

// SCHL scholastic cookie
const SCHL_AUTH: string = 'SCHL_AUTH';

const deleteSCHLCookies = () => {
    let scholasticCookies: string[] = [SPS_BUS, SPS_SESSION, SPS_SESSION_SECURE, SPS_TSP, SPS_TSP_SECURE, SPS_UD, XUS_EI, SCHL_AUTH];

    scholasticCookies.forEach(cookie => {
        if(getCookieValue(cookie)){
            console.log('previously scholastic set cookie: ' + cookie + ' exists with value: ' + getCookieValue(cookie));
            deleteCookie(cookie);
            console.log('value of ' + cookie + ' after deletion:' + getCookieValue(cookie));
        }
    });
}

export default deleteSCHLCookies;