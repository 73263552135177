import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useRegisterSchool = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const registerSchool = async (body: any) => {
    return await makeApiRequest(`${apiBase}/api/submit-school`, body);
  };

  return [registerSchool];
};

export default useRegisterSchool;