import React, { FC, useContext, useEffect, useState } from 'react';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useBroadcast from '../../../hooks/useBroadcast';
import refProps from '../../../interfaces/refProps';
import isBaseline from '../../../utils/is-baseline';
import Spinner from '../../../components/Spinner/Spinner';
import styles from './RegisterBFC.module.scss';
import usePasswordSubmit from '../../../hooks/usePasswordSubmit';
import { isEducator, isEducatorRef } from '../../../utils/is-educator';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../../providers/ConfigContext';
import useDumbleData from '../../../hooks/useDumbleData';
import dumbleDataProps from '../../../interfaces/dumbleDataProps';
import useDebounce from '../../../hooks/useDebounce';
import validate from '../../../utils/validate';
import useVerifyBookfair from '../../../hooks/useVerifyBookfair';
import CheckEmail from '../../../components/CheckEmail/check-email';
import useVerifyEmail from '../../../hooks/useVerifyEmail';


interface RegisterBFCProps {}


const RegisterBFC = (props: refProps) => {
  const[email, setEmail] = useState({
    value: "",
    touched: false,
    error: ""
  });
  const emailDebouncer = useDebounce();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [school,setSchool] = useState("");
  const[status, setStatus] = useState(200);

  const[isLoading, setIsLoading] = useState(false);
  const [verifyBookfair] = useVerifyBookfair();
  let history = useHistory();
  const [verifyEmail] = useVerifyEmail();
  const welcomeMessage = "Please enter the email address you gave to your Book Fair Consultant for the Toolkit. We'll check if you are already registered anywhere at scholastic.com.";
  const alreadyExistsError = "An account already exists for this email address. Please return to sign-in. If you require further assistance, contact customer service via email or at 1-800-724-6527.";
  const wrongEmailError = "The email you entered does not match the email provided for the Toolkit. To verify the email address, Book Fair Chairpeople please contact your Book Fair Consultant. Volunteers, please contact your Chairperson. Continue without Toolkit access.";
  const cancel = () =>{

  }
  const submit =  async () =>{
    setIsLoading(true);
    const {data, error} = await verifyBookfair(email.value);
    if(error || data.status == "-1"){
      setIsLoading(false);
      setStatus(data?.status == "-1" ? 418 : error?.status);
    } else {
      setSchool(data.schoolInfo.schoolUCN);
      checkEmail();
    }
  }

  const checkEmail = async () => {
    const {data, error} = await verifyEmail(email.value, "");
    if(error != null){
      setIsLoading(false);
      if( error.status == 404){
      history.push({
        pathname: '/register/my-info.html',
        state: {
          school: school,
          email: email.value
        }
      });
    } else {
      setIsLoading(false);
      setStatus(error.status);
      }
    } else if (data != null){
      setIsLoading(false);
      setStatus(403);
    }
  }

  useEffect(()=>{
    const timeout = emailDebouncer(
      setEmail.bind(
        RegisterBFC,
        handleInput('error', (validate(email.value, ['required','emailFormat'])), email)
      ),
      300
    )
    return () => clearTimeout(timeout);
  },[email.value])

  useEffect(()=> {
    setButtonDisabled(email.error != "" || !email.touched);
  },[email, status])

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (event.code === "Enter" && buttonDisabled) {
        submit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email,isLoading])

  const handleInput = ((type: string, input: any, field: object) => {
    const newField: any = {...field};
    newField[type] = input;
    return newField;
  })
  
  return (
  <div className={styles.RegisterBFC} data-testid="RegisterBFC">
    {isLoading && <div>
        <div className={styles.spinnerModal}>
          <div className={styles.spinnerPosition}>
            <Spinner />
          </div>
        </div>
    </div>}

    <div className={styles.header}>
      Register
    </div>
    {status == 200 && //If email already exists
      <div className={styles.bodyText}>
        {welcomeMessage}
      </div>
    }
    {status == 418 && //If email already exists
      <div className={styles.errorBodyText}>
        {wrongEmailError}
      </div>
    }
    {status == 403 && //If email already exists
      <div className={styles.errorBodyText}>
        {alreadyExistsError}
      </div>
    }
    <div>
    <input className={`${styles.inputGrey} ${email.error == "" || !email.touched ? '' : styles.errorField}`}
          id="email"
          aria-label="enter email"
          name="email"
          value={email.value}
          placeholder="Email"
          onSelect={()=>setEmail(handleInput('touched', true, email))}
          onChange={(e)=>{if(!isLoading)setEmail(handleInput('value', e.target.value, email))}}
        />
      <div data-testid="emailErr" className={`${email.error != ""  && email.touched ? styles.errorMessage : styles.disable}`}>{email.error}</div>
    </div>
    <div className={styles.buttonContainer}>
      <SubmitButton outlined={true} name={"cancel"} submitFunction={cancel} id={"register-bfc-cancel-button"}/>
      <SubmitButton isDisabled={buttonDisabled}  outlined={false} name={"continue"} submitFunction={submit} id={"register-bfc-submit-button"}/>
    </div>
    </div>
  );
};

export default RegisterBFC;
