export const customStyles = {
    control: (base) => ({
      ...base,
      textAlign: "left",
      marginTop: "20px",
      paddingLeft: "3px",
      padding: "2px",
      width: "-webkit-calc(100% + 5px)"
    }),
    
    menu: (base) => ({
      ...base,
      textAlign: "left",
      width: "-webkit-calc(100% + 15px)"
    }),
  
    option: (provided, state) => ({
      ...provided,
      padding: '15px 15px',
      color: "#9a7cc5",
      backgroundColor: "#fff",
      borderRadius: "8px",
      margin: '2px',
      display: 'block',
      width: "99%",
  
      '&:hover': {
        backgroundColor: '#9a7cc5',
        borderRadius: '8px',
        color: '#fff',
        cursor: 'pointer',
        textDecoration: "underline",
      },
    }),
  };
  