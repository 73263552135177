import React, {useState, MouseEvent, useEffect, useRef, useMemo} from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import usePasswordReset from '../../../hooks/usePasswordReset';
import validate from '../../../utils/validate';
import styles from './ResetPassword.module.scss';
import checkMark from '../../../styles/images/check-mark.svg';
import eyeHide from '../../../styles/images/eye-hide.svg';
import eyeShow from '../../../styles/images/eye-show.svg';
import bullet from '../../../styles/images/filled-circle.svg';
import clubsMsg from '../../../interfaces/clubsMsgConfig';
import sdmMsg from '../../../interfaces/sdmMsgConfig';
import baselineMsg from '../../../interfaces/baselineMsgConfig';
import useVerifyToken from '../../../hooks/useVerifyToken';
import close from '../../../styles/images/close.svg';
import useBroadcast from '../../../hooks/useBroadcast';
import refProps from '../../../interfaces/refProps';
import isBaseline from '../../../utils/is-baseline';
import useDumbleData from '../../../hooks/useDumbleData';
import dumbleDataProps from '../../../interfaces/dumbleDataProps';
import useResetPasswordRefRedirect from '../../../hooks/useResetPasswordRefRedirect';

const ResetPassword = (props: refProps) => {
  
  let history =  useHistory();
  const passwordDebouncer = useDebounce();
  const confirmedPasswordDebouncer = useDebounce();
  const [resetPassword] = usePasswordReset();
  const [verifyEmailToken] = useVerifyToken();
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("example token");
  const [status, setStatus] = useState(200);
  const [isDisabled, setIsDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const pathname: string = "sign-in/";
  let  [isTokenValid, setTokenFlag] = useState(true);
  const broadcast = useBroadcast();
  const { referrer, chat, reg } = props;
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;
  let [unavailableErrorMsg, setUnavailableErrorMsg] = useState("");
  const resetPasswordRefRedirect = useResetPasswordRefRedirect();

  useMemo(()=>{
    if (referrer === 'clubs') {
      setUnavailableErrorMsg(clubsMsg.unavailableError);
    } else if (referrer === 'sdm'){
      setUnavailableErrorMsg(sdmMsg.unavailableError);
    } else {
      setUnavailableErrorMsg(baselineMsg('unavailableError', referrer));
    }
  },[referrer, chat, unavailableErrorMsg]);

  const handleShow = ()=>{
    setShowPassword(!showPassword);
  }

  const [password, setPassword]= useState({
    password: '',
    error: 'This field is required.',
    touched: false
  })

  const [confirmedPassword, setConfirmedPassword]= useState({
    password: '',
    error: '',
    touched: false
  })

  useEffect(()=> {
    let pageData = {
      name: 'MyScholastic:Login:ResetPassword',
      type: 'Login',
      siteSectionLevel1: 'MyScholastic:Login:ResetPassword'
    };
    //edge case but need to clear it out incase user goes to forgot password page on same instance
    if (dumbledata?.user?.userId !== ''){
      let userInfoReset = {
        loginStatus: false,
        userId: '',
        userType: ''
      }
      dumbledata = dumbleDataHook('user', userInfoReset);
    }
    dumbledata = dumbleDataHook('page', pageData);
  }, []);

  const handleInput = ((type: string, input: any, field: object) => {
    const newField: any = {...field};
    newField[type] = input;
    return newField;
  })

  const checkPassword = (()=>{
    if ((password.password == confirmedPassword.password || !confirmedPassword.touched)){
      setConfirmedPassword(handleInput('error', "", confirmedPassword));
    } else if ((password.password !== confirmedPassword.password && confirmedPassword.touched)){
      setConfirmedPassword(handleInput('error', "Your password did not match. Try again.", confirmedPassword));
    }
  })

  const submitNewPassword = async () => {
    const {data, error} = await resetPassword(token, password.password, referrer);
    if(error){
      setStatus(error.status);
      let err = {
        errorType: error.status    
      };
      dumbledata = dumbleDataHook('error', err);
      return;
    } else {
      setStatus(200); //TO DO: the logic that makes it so theres a "successful reset password" message at sign in
      window.location.href = resetPasswordRefRedirect(referrer);
    }
  }

  //need to invoke on page load
  useEffect(()=>{
    verifyToken();
  },[]);

  //api call to verify token is still valid
  const verifyToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const urlReferrer = urlParams.get('ref');
    console.log("url ref is", urlReferrer);
    console.log("referrer from props", referrer);
    //if token exists and its not empty or null we will make an api call
    if(urlToken && (urlToken !== null || urlToken !== "")) {
      const {data, error} = await verifyEmailToken(urlToken);
      if(error){
        console.log({error});
        setTokenFlag(false);
      } else {
        console.log({data});
        setTokenFlag(true);
        setToken(urlToken);
      }
    }
  }

  const redirectToEmail = (()=>{
    history.push({
      pathname: '/sign-in/forgot-password.html'
    });
  });

  useEffect(()=>{
    setIsDisabled(
      password.password !== confirmedPassword.password ||
      password.error !== "" ||
      confirmedPassword.error !== "" ||
      isTokenValid !== true
    );
  },[password, confirmedPassword, isTokenValid]);

  useEffect(()=>{
    const timeout = passwordDebouncer(
      setPassword.bind(
        ResetPassword,
        handleInput('error', (validate(password.password, ['password'])), password)
      ),
      300
    )
    return () => clearTimeout(timeout);
  },[password.password]);

  useEffect(()=>{
    const timeout = confirmedPasswordDebouncer(
      checkPassword,
      300
    )
    return () => clearTimeout(timeout);
  },[password.password, confirmedPassword.password]);

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  } 

  const openToggle = async (event: any) =>{
    event.persist();
    await timeout(300);
    setShow(true);
    setTarget(event.target);
  }

  const closeToggle = () =>{
    setShow(false);
  }

  const cancel = () => {
    if(isBaseline(referrer)){
      broadcast({
        status: 'CLOSE',
        data: {},
        callbacks: ['myScholasticOnLoginClose']
      }, referrer);
      console.log({ status: 'CLOSE', data: {}, callbacks: ['myScholasticOnLoginClose']});
    } else {
      broadcast({
        status: 'CLOSE',
        data: {}
      }, referrer);
      console.log({ status: 'CLOSE', data: {}});
    }
  }

  useEffect(()=>{
    const listener = (event: { code: string; }) => {
      if (event.code === "Enter" && !isDisabled) {
        submitNewPassword();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  })

  return (
    <div className={styles.container} ref={ref} >
      <img src={close} alt="close icon" className={`${styles.close} ${isBaseline(referrer) ? styles.disable : ''}`} onClick={cancel}/>
      <div className={styles.headerText}>Reset your password</div>
      {isTokenValid === false && <div className={styles.tokenError}>The Link has expired, please click on Resend Link to have another email sent to you.</div>}
      <div className={styles.inputContainer}>
       <div className={styles.passwordContainer}> 
       <i className={styles.eye}><img 
        tabIndex={0}
        alt="show or hide password"
        src={showPassword ? eyeHide : eyeShow} 
        onClick={handleShow}
        onKeyPress={(e) => {if(e.key === 'Enter' || e.key === ' '){handleShow()}}}
        />
        </i>
        <input 
        autoFocus
        id="reset-new-password"
        aria-label="new password"
        type={showPassword ? "text" : "password"}
        placeholder="New password"
        className={`${styles.inputGrey} ${password.error === '' || !password.touched ? '' : styles.errorField}`}
        onSelect={()=>setPassword(handleInput('touched', true, password))}
        onChange={(e) => setPassword(handleInput('password', e.target.value, password))}
        onFocus={(e)=>openToggle(e)}
        onBlur={()=>closeToggle()}
        />
        
      </div>
        <input 
        id="reset-confirm-password"
        aria-label="confirm new password"
        type="password"
        placeholder="Confirm New password"
        className={`${styles.inputGrey} ${confirmedPassword.error === '' || !confirmedPassword.touched ? '' : styles.errorField}`}
        onSelect={()=>setConfirmedPassword(handleInput('touched', true, confirmedPassword))}
        onChange={(e) => setConfirmedPassword(handleInput('password', e.target.value, confirmedPassword))}
        />
        <div data-testid="alreadyUsedErr" className={`${status === 409 ? styles.errorMessage : styles.disable}`}>The last 3 passwords cannot be used</div> {/* if 409 then thats the "last 3 passwords were the same" */}
        <div data-testid="unavailableErr" className={`${(status !== 409 && status !== 200) ? styles.errorMessage : styles.disable}`}>{unavailableErrorMsg}</div> {/* if unknown then show generic error message */}
        <div data-testid="doesNotMatchErr" className={`${confirmedPassword.error !== '' ? styles.errorMessage : styles.disable}`}>{confirmedPassword.error}</div> {/* show this error if they're not the same passwords */}
      </div>
      <div className={styles.buttonContainer}>
        <button
        id="reset-password-button"
        aria-label="reset password"
        disabled={isDisabled}
        className = {`${styles.button} ${styles.purple} ${isDisabled ? styles.disabled : ''}`}
        onClick={submitNewPassword}
        onKeyPress={(e) => {if(e.key === 'Enter'){e.preventDefault(); submitNewPassword();}}}>
          RESET PASSWORD
        </button>
        { isTokenValid === false &&
          <button
          id="reset-resend-link-button"
          aria-label="resend reset password email"
          className = {`${styles.button} ${styles.purple} ${isDisabled ? styles.disabled : ''}`}
          onClick={redirectToEmail}>
            RESEND LINK
          </button>
        }
      </div>
      
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}>
        <Popover id="popover-contained" className={styles.popup}>
          <Popover.Content>
            <div className={styles.pleaseText}>Please enter a password that meets all criteria</div>
            <div className={styles.popOverText}><div className={styles.createText}>To create a strong password use</div>
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['passwordMin']) === '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['passwordMin']) === '' ? checkMark : bullet} alt="password minimum length requirement checkmark"
                /></i>
                <div className={`${validate(password.password, ['passwordMin']) === '' ? styles.success : ''}`}>7 or more characters</div>
              </div>
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['passwordNumLetter']) === '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['passwordNumLetter']) === '' ? checkMark : bullet} alt="password number and letter requirement checkmark"
                /></i>
                <div className={`${validate(password.password, ['passwordNumLetter']) === '' ? styles.success : ''}`}>At least 1 letter and 1 number</div>
              </div>
              {/* // Suppressed until we communicate with other teams
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['oneUpperOneLower']) == '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['oneUpperOneLower']) == '' ? checkMark : bullet}
                /></i>
                <div className={`${validate(password.password, ['oneUpperOneLower']) == '' ? styles.success : ''}`}>Upper and lowercase letters</div>
              </div>
              <div className={styles.listItem}>
                <i className={`${validate(password.password, ['passwordSpecial']) == '' ? styles.checkmark : styles.bullet}`}><img 
                src={validate(password.password, ['passwordSpecial']) == '' ? checkMark : bullet}
                /></i>
                <div className={`${validate(password.password, ['passwordSpecial']) == '' ? styles.success : ''}`}>Special characters</div>
              </div>*/}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  )
}

export default ResetPassword;
