import React from 'react';
import styles from './SubmitButton.module.scss';

const SubmitButton = (params: any) => {
  return (
    <button 
      aria-label={"submit button to "+`${params.name}`}
      id={params.id}
      disabled={params.isDisabled} 
      className={`${params.outlined ? styles.buttonOutlined : styles.button} ${styles.purple} ${params.isDisabled ? styles.disabled: ''}`}
      onClick={params.submitFunction}>
      {params.name}
    </button>
  )
}

export default SubmitButton;