import { useContext, useCallback } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import resetPasswordRedirectConfig from '../interfaces/resetPasswordRedirectConfig';
import validReferrer from '../utils/valid-referrer';

const useResetPasswordRefRedirect = () => {
    const appConfig = useContext(ConfigContext);
    
    return useCallback((ref: string) => {
        const { env } = appConfig;

        if(env && validReferrer(ref)){
            return redirectBasedOnReferrer(env, ref);
        } else {
            console.log("Received " + ref + " referrer in " + env + ", redirecting to corphome as fallback option");
            return 'https://www.scholastic.com/home';
        }
    }, [appConfig])
}

const redirectBasedOnReferrer = ((env: string, referrer: string) => {
    switch(env){
        case 'preprod':
        case 'prod':
            return resetPasswordRedirectConfig['prod'][referrer];
        case 'stage':
            return resetPasswordRedirectConfig['stage'][referrer];
        case 'perf':
            return resetPasswordRedirectConfig['perf'][referrer];
        default:
            return resetPasswordRedirectConfig['qa'][referrer];
    }
});

export default useResetPasswordRefRedirect;