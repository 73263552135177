import React from 'react';
import styles from './header.module.scss';
import logo from '../../styles/images/scholastic-logo-updated.svg';

const Header = () => {
  return (
    <div className={styles.header} role="banner">
      <img src={logo} alt="scholastic-logo" />
    </div>
  )
}

export default Header;
