import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const usePasswordReset = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('PUT');

  const resetPassword = async (token: string, password: string, ref:string) => {
    return await makeApiRequest(`${apiBase}/api/reset-password?token=${token}&ref=${ref}`, {"password": password});
  };

  return [resetPassword];
};

export default usePasswordReset;