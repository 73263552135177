import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useVerifyBookfair = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('GET');

  const verifyBookfair = async (email: string) => {
    return await makeApiRequest(`${apiBase}/api/email/bookFairs?email=${email}`, { "email": email});
  };

  return [verifyBookfair];
};

export default useVerifyBookfair;