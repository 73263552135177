
import React, { useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";

const RouterGuard = ({path, component: Component, referrer, chat, reg, auth}) => {
   
    const helpDebug = () => {console.log("Router guard says auth is: " + auth);}
   
    useEffect(()=>{
        helpDebug();
    },[]);

    return(
        <Route render={() => (
            auth === true
                ? <Component path={path} referrer={referrer} chat={chat} reg={reg}/>
                : <Redirect to='/sign-in.html' />
        )} />
    )
}

export default RouterGuard;