export const resetPasswordRedirectConfig: any = {
    qa: {
        tso: "https://shop-qa.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsocheckout: "https://shop-qa.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsoprntquote: "https://shop-qa.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        sso: "https://shop-qa.scholastic.com/parent-ecommerce/parent-store.html",
        ofesso: "https://shop-qa.scholastic.com/parent-ecommerce/parent-store-2.html",
        ssocheckout: "https://shop-qa.scholastic.com/parent-ecommerce/parent-store.html",
        ssocoreg: "https://shop-qa.scholastic.com/parent-ecommerce/parent-store.html",
        ssocosignin: "https://shop-qa.scholastic.com/parent-ecommerce/parent-store.html",
        beta: "https://www-qa64.scholastic.com/teachers/teaching-tools/home.html",
        cptk: "https://bookfairsqa.scholastic.com/bookfairs/landing-page.html",
        ewallet: "https://bookfairsqa.scholastic.com/bookfairs/landing-page.html",
        vms: "https://vms-qa.scholastic.com/#/experience/login",
        tcs: "https://www-qa64.scholastic.com/teachers/home/",
        pcs: "https://www-qa64.scholastic.com/parents/home.html",
        lah: "https://learnathome-aem64-qa.scholastic.com/",
        classmags: "https://classroommagazines-aem64-qa.scholastic.com/",
        sdm: "https://dp-portal-qa1.scholastic.com/#/signin/staff",
        clubs: "https://clubs3qa1.scholastic.com/home",
        corphome: "https://www-qa64.scholastic.com/home",
        bw: "https://bookwizard-qa.scholastic.com/",
        tcb: "https://teachables-qa.scholastic.com/teachables/guesthomepage.html",
        myschl: "https://myaccounts-qa.scholastic.com/",
        rif: "https://shop-qa.scholastic.com/teachers-ecommerce/nat/natpartner.html",
        cos: "https://shop-qa.scholastic.com/teachers-ecommerce/cos/sample-order-homepage.html",
        face: "https://shop-qa.scholastic.com/teachers-ecommerce/face/facepartner.html",
        lp: "https://shop-qa.scholastic.com/teachers-ecommerce/lp/lppartner.html",
        nys: "https://shop-qa.scholastic.com/teachers-ecommerce/nys/nyspartner.html",
        usor: "https://usofreaders-qa.scholastic.com",
        ror: "https://shop-qa.scholastic.com/teachers-ecommerce/ror/rorpartner.html",
        cmplus: "https://shop-classroommagazines-qa.scholastic.com",
        storyvoice: "https://storyvoice-qa.scholastic.com",
        sdc: "https://sdc-qa.scholastic.com",
        literacy: "https://literacy-qa.scholastic.com"
    },
    stage: {
        tso: "https://shop-stage.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsocheckout: "https://shop-stage.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsoprntquote: "https://shop-stage.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        sso: "https://shop-stage.scholastic.com/parent-ecommerce/parent-store.html",
        ofesso: "https://shop-stage.scholastic.com/parent-ecommerce/parent-store-2.html",
        ssocheckout: "https://shop-stage.scholastic.com/parent-ecommerce/parent-store.html",
        ssocoreg: "https://shop-stage.scholastic.com/parent-ecommerce/parent-store.html",
        ssocosignin: "https://shop-stage.scholastic.com/parent-ecommerce/parent-store.html",
        beta: "https://www-stage64.scholastic.com/teachers/teaching-tools/home.html",
        cptk: "https://bookfairsstage.scholastic.com/bookfairs/landing-page.html",
        ewallet: "https://bookfairsstage.scholastic.com/bookfairs/landing-page.html",
        vms: "https://vms-stage.scholastic.com/#/experience/login",
        tcs: "https://www-stage64.scholastic.com/teachers/home/",
        pcs: "https://www-stage64.scholastic.com/parents/home.html",
        lah: "https://learnathome-aem64-stage.scholastic.com/",
        classmags: "https://classroommagazines-aem64-stage.scholastic.com/",
        sdm: "https://dp-portal-qa1.scholastic.com/#/signin/staff",
        clubs: "http://clubs3stg1.scholastic.com/",
        corphome: "https://www-stage64.scholastic.com/home",
        bw: "https://bookwizard-stage.scholastic.com/",
        tcb: "https://teachables-stage.scholastic.com/teachables/guesthomepage.html",
        myschl: "https://myaccounts-stage.scholastic.com/",
        rif: "https://shop-stage.scholastic.com/teachers-ecommerce/nat/natpartner.html",
        cos: "https://shop-stage.scholastic.com/teachers-ecommerce/cos/sample-order-homepage.html",
        face: "https://shop-stage.scholastic.com/teachers-ecommerce/face/facepartner.html",
        lp: "https://shop-stage.scholastic.com/teachers-ecommerce/lp/lppartner.html",
        nys: "https://shop-stage.scholastic.com/teachers-ecommerce/nys/nyspartner.html",
        usor: "https://usofreaders-qa.scholastic.com",
        ror: "https://shop-stage.scholastic.com/teachers-ecommerce/ror/rorpartner.html",
        cmplus: "https://shop-classroommagazines-stage.scholastic.com",
        storyvoice: "https://storyvoice-stage.scholastic.com",
        sdc: "https://sdc-stage.scholastic.com",
        literacy: "https://literacy-stage.scholastic.com"
    },
    perf: {
        tso: "https://shop-perf.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsocheckout: "https://shop-perf.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsoprntquote: "https://shop-perf.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        sso: "https://shop-perf.scholastic.com/parent-ecommerce/parent-store.html",
        ofesso: "https://shop-perf.scholastic.com/parent-ecommerce/parent-store-2.html",
        ssocheckout: "https://shop-perf.scholastic.com/parent-ecommerce/parent-store.html",
        ssocoreg: "https://shop-perf.scholastic.com/parent-ecommerce/parent-store.html",
        ssocosignin: "https://shop-perf.scholastic.com/parent-ecommerce/parent-store.html",
        beta: "https://www-perf.scholastic.com/teachers/teaching-tools/home.html",
        cptk: "https://bookfairsperf.scholastic.com/bookfairs/landing-page.html",
        ewallet: "https://bookfairsperf.scholastic.com/bookfairs/landing-page.html",
        vms: "https://vms-perf.scholastic.com/#/experience/login",
        tcs: "https://www-perf.scholastic.com/teachers/home/",
        pcs: "https://www-perf.scholastic.com/parents/home.html",
        lah: "https://learnathome-aem-perf.scholastic.com/",
        classmags: "https://classroommagazines-aem-perf.scholastic.com/",
        sdm: "https://dp-portal-perf1.scholastic.com/#/signin/staff",
        clubs: "https://clubs3perf1.scholastic.com/home",
        corphome: "https://www-perf.scholastic.com/home",
        bw: "https://bookwizard-perf.scholastic.com/",
        tcb: "https://teachables-perf.scholastic.com/teachables/guesthomepage.html",
        myschl: "https://myaccounts-perf.scholastic.com/",
        rif: "https://shop-perf.scholastic.com/teachers-ecommerce/nat/natpartner.html",
        cos: "https://shop-perf.scholastic.com/teachers-ecommerce/cos/sample-order-homepage.html",
        face: "https://shop-perf.scholastic.com/teachers-ecommerce/face/facepartner.html",
        lp: "https://shop-perf.scholastic.com/teachers-ecommerce/lp/lppartner.html",
        nys: "https://shop-perf.scholastic.com/teachers-ecommerce/nys/nyspartner.html",
        usor: "https://usofreaders-perf.scholastic.com",
        ror: "https://shop-perf.scholastic.com/teachers-ecommerce/ror/rorpartner.html",
        cmplus: "https://shop-classroommagazines-perf.scholastic.com",
        storyvoice: "https://storyvoice-perf.scholastic.com",
        sdc: "https://sdc-perf.scholastic.com",
        literacy: "https://literacy-perf.scholastic.com"
    },
    prod: {
        tso: "https://shop.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsocheckout: "https://shop.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        tsoprntquote: "https://shop.scholastic.com/teachers-ecommerce/teacher/tsohomepage.html",
        sso: "https://shop.scholastic.com/parent-ecommerce/parent-store.html",
        ofesso: "https://shop.scholastic.com/parent-ecommerce/parent-store.html",
        ssocheckout: "https://shop.scholastic.com/parent-ecommerce/parent-store.html",
        ssocoreg: "https://shop.scholastic.com/parent-ecommerce/parent-store.html",
        ssocosignin: "https://shop.scholastic.com/parent-ecommerce/parent-store.html",
        beta: "https://www.scholastic.com/teachers/teaching-tools/home.html",
        cptk: "https://bookfairs.scholastic.com/bookfairs/landing-page.html",
        ewallet: "https://bookfairs.scholastic.com/bookfairs/landing-page.html",
        vms: "https://volunteer.scholastic.com/#/experience/login",
        tcs: "https://www.scholastic.com/teachers/lessons-and-ideas/",
        pcs: "https://www.scholastic.com/parents/home.html",
        lah: "https://learnathome.scholastic.com/",
        classmags: "https://classroommagazines.scholastic.com/",
        sdm: "https://digital.scholastic.com/#/signin/staff",
        clubs: "https://clubs.scholastic.com/home",
        corphome: "https://www.scholastic.com/home",
        bw: "https://bookwizard.scholastic.com/",
        tcb: "https://teachables.scholastic.com/teachables/guesthomepage.html",
        myschl: "https://account.scholastic.com/",
        rif: "https://shop.scholastic.com/teachers-ecommerce/nat/natpartner.html",
        cos: "https://shop.scholastic.com/teachers-ecommerce/cos/sample-order-homepage.html",
        face: "https://shop.scholastic.com/teachers-ecommerce/face/facepartner.html",
        lp: "https://shop.scholastic.com/teachers-ecommerce/lp/lppartner.html",
        nys: "https://shop.scholastic.com/teachers-ecommerce/nys/nyspartner.html",
        usor: "https://usofreaders.scholastic.com",
        ror: "https://shop.scholastic.com/teachers-ecommerce/ror/rorpartner.html",
        cmplus: "https://shop-classroommagazines.scholastic.com",
        storyvoice: "https://storyvoice.scholastic.com",
        sdc: "https://sdc.scholastic.com",
        literacy: "https://literacy.scholastic.com"
    }
}

export default resetPasswordRedirectConfig;