import React, { useState, useEffect }  from 'react';
import styles from './check-email.module.scss';
import useRequestPasswordResetApi  from '../../hooks/requestPasswordReset';
import { Link , useLocation, useHistory } from 'react-router-dom';
import refProps from '../../interfaces/refProps';
import useBroadcast from '../../hooks/useBroadcast';
import close from '../../styles/images/close.svg';
import isBaseline from '../../utils/is-baseline';
import isPartner from '../../utils/is-partner';
import useDumbleData from '../../hooks/useDumbleData';
import dumbleDataProps from '../../interfaces/dumbleDataProps';
import { directive } from '@babel/types';

const CheckEmail = (props: refProps) => {
    const skipEmail: boolean = false;
    const [status, setStatus] = useState(200);
    const [resetPassword] = useRequestPasswordResetApi();
    const location = useLocation<string>();
    let history =  useHistory();
    const { referrer, chat, reg } = props;
    const broadcast = useBroadcast();
    const dumbleDataHook = useDumbleData;
    let dumbledata: dumbleDataProps;

    const requestResetPassword = async () => {
        // this api call will pass email to MSS to send a password resent link to the email with a token
        let email = '';
        if (location.state) {
            email = location.state;
        }
        const { data, error } = await resetPassword(email, skipEmail, referrer);
        if (error) {
          console.error("Error Requesting Password reset", error.status);
          setStatus(error.status);
          if (error.status === 422) {
            history.push({
                pathname: '/sign-in.html'
              });
          }
          return;
        } else {
          //we will remove this later on, we need to set up email service
          console.log("data", status, data);
          setStatus(200);
        }
      };

      const cancel = () => {
        if(isBaseline(referrer)){
          broadcast({
            status: 'CLOSE',
            data: {},
            callbacks: ['myScholasticOnLoginClose']
          }, referrer);
          console.log({ status: 'CLOSE', data: {}, callbacks: ['myScholasticOnLoginClose']});
        } else {
          broadcast({
            status: 'CLOSE',
            data: {}
          }, referrer);
          console.log({ status: 'CLOSE', data: {}});
        }
      }

      useEffect(()=> {
        let pageData = {
          name: 'MyScholastic:Login:ForgotPassword',
          type: 'Login',
          siteSectionLevel1: 'MyScholastic:Login:ForgotPassword'
        };
        dumbledata = dumbleDataHook('page', pageData);
      }, []);

    return (
        <div className={styles.container}>
            <img src={close} alt="close icon" className={`${styles.close} ${isBaseline(referrer) ? styles.disable : ''}`} onClick={cancel}/>
            <div className={styles.header}>Please check your email</div>
            <div className={styles.instructions}>We've sent you a link to reset your password.</div>
            {isPartner(referrer) && <div className={styles.instructions}>You may close this modal.</div>}
            {!isPartner(referrer) &&
              <div className={styles.buttonContainer}>
                  <Link to={{
                      pathname: '/sign-in.html'}}>
                          <button 
                            id="check-email-back-button"
                            aria-label="goes back to sign in page"
                            className={`${styles.button} ${styles.purple} ${styles.backToSignIn}`}>
                            BACK TO SIGN IN
                          </button>
                  </Link>
                <div className={styles.resendLink}>
                  <a id="check-email-resend-link" className={styles.resend} href='#' onClick={requestResetPassword}>Resend Link</a>
                </div>
              </div>
            }
        </div>
    );

};

export default CheckEmail;