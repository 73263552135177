import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useVerifyEmail = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const verifyEmailResponse = async (email: string, reCaptchaToken: string) => {
    return await makeApiRequest(`${apiBase}/api/verify-email?reCaptchaToken=` + reCaptchaToken, { "username": email});
  };

  return [verifyEmailResponse];
}

export default useVerifyEmail;