import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useGetCountries = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const getCountries =  async () => {
        return await makeApiRequest(`${apiBase}/lookup/countries`, {});
    };

    return [getCountries];
}

export default useGetCountries;