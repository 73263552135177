import React, { FC, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { GRADES } from '../../../constants/grades';
import { POSITIONS } from '../../../constants/positions';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import refProps from '../../../interfaces/refProps';
import styles from './MyRole.module.scss';
import close from '../../../styles/images/close.svg';
import {customStyles} from "../../../utils/select-custom-styles-role";
import { grade } from '../../../interfaces/grade';
import useDumbleData from '../../../hooks/useDumbleData';
import dumbleDataProps from '../../../interfaces/dumbleDataProps';

interface MyRoleProps {}

const MyRole = (props: refProps) => {
  const { referrer, chat, reg } = props;
  let history = useHistory();
  const location = useLocation<any>();
  const [firstName, setFirstName] = useState(location.state?.firstName);
  const [lastName, setLastName] = useState(location.state?.lastName);
  const [email, setEmail] = useState(location.state?.email);
  const [password, setPassword] = useState(location.state?.password);
  const [gradeForms, setGradeForms] = useState([
    {label: "", value: "", gradeRole: ""}
  ])
  const [role, setRole] = useState(''); //parent, teacher, admin
  const [position, setPosition] = useState(''); //position for admins
  const [isDisabled, setIsDisabled] = useState(true);

  const ROLES = [{value: "Parent", label: "Parent/Family"}, {value: "Teacher", label: "Teacher"}, {value: "Administrator", label: "Administrator/Other"}];
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;

  useEffect(()=> {
    let pageData = {
      name: 'MyScholastic:Registration:My Role',
      type: 'Registration',
      siteSectionLevel1: 'MyScholastic:Registration:My Role'
    };
    dumbledata = dumbleDataHook('page', pageData);
  }, []);
  
  const prev = () =>{
    history.push({
      pathname: "/register/register-password.html",
    });
  }

  const next = () =>{
    if(role == 'Parent'){
      history.push({
        pathname: '/register/my-kids.html',
        state: {
          role: role,
          password: password,
          firstName: firstName,
          lastName: lastName,
          email: email
        }
      });
    } else if (role == 'Teacher') {
      history.push({
        pathname: '/register/my-school.html',
        state: {
          role: role,
          grades: gradeForms,
          password: password,
          firstName: firstName,
          lastName: lastName,
          email: email
        }
      });
    } else if (role == 'Administrator'){
      history.push({
        pathname: '/register/my-school.html',
        state: {
          role: role,
          position: position,
          password: password,
          firstName: firstName,
          lastName: lastName,
          email: email
        }
      });
    }
  }

  useEffect(()=>{
    setIsDisabled(role === '');
  }, [role]);

  const addGradeForm = () =>{
    setGradeForms([...gradeForms, { label: "", value: "", gradeRole: "" }]);
  }

  const updateGrades = (index: number, grade: grade) => {
    const newGradeForms = [...gradeForms];
    newGradeForms[index] = grade;
    setGradeForms(newGradeForms);
  };

  const deleteChildForm = (index: number) => {
    setGradeForms(gradeForms.filter(grade => grade.label != gradeForms[index].label));
  };

  useEffect(()=>{ //clear info
    setGradeForms([{ label: "", value: "", gradeRole: "" }]); 
    setPosition("");
  },[role])

  useEffect(()=>{
    setIsDisabled(
      role === "" ||
      role === "Administrator" && position === "" ||
      role === "Teacher"  && 
      gradeForms.some(element => element.label == "")
    );
  },[role,position,gradeForms, gradeForms.length])

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (event.code === "Enter" && !isDisabled) {
        next();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isDisabled])

  return (
  <div className={styles.MyRole} data-testid="MyRole">
    <div className={styles.header}>
      My Role
    </div>
    <Select options={ROLES} className={styles.selectionInput} styles={customStyles} placeholder="Roles" onChange={(e: any) => setRole(e.value)}/>
    {role === 'Administrator' && 
      <Select 
        options={POSITIONS.sort((a,b) => a.label.localeCompare(b.label))} 
        styles={customStyles} 
        placeholder="Position" 
        onChange={(e: any) => setPosition(e.value)} 
        isSearchable={true}/>
    }
     {role === 'Teacher' &&
     <div className={styles.grade}>    
    { gradeForms.map((grade, key) => (
      <div className={styles.gradeContainer}>
        <Select 
          className={styles.gradeInput} 
          id={key.toString()} 
          options={GRADES.filter(grade => !gradeForms.some(element => element.label == grade.label))} 
          placeholder="Grade" 
          styles={customStyles} 
          onChange={(e: any) => updateGrades(key, e)} 
          isSearchable={true}/>
        {key >= 1 &&
        <img src={close} alt="close icon" className={`${styles.close} ${key < 1 ? styles.disable : ''}`} onClick={() => deleteChildForm(key)}/>
        }
      </div>
      ))}
    </div>
    }
    {role === 'Teacher' &&
      <div className={styles.gradeAddContainer}>
      <span className={`${styles.gradeAddDash} ${styles.left}`}></span>
      <div className={styles.gradeAdd} onClick={() => addGradeForm()}>
      + ADD A GRADE
      </div>
      <span className={`${styles.gradeAddDash} ${styles.right}`}></span>
    </div>
    }    
    <div className={styles.buttonContainer}>
      <SubmitButton outlined={false} name={'Previous'} submitFunction={prev} id={"register-myrole-prev-button"}/>
      <SubmitButton outlined={false} isDisabled={isDisabled} name={'Next'} submitFunction={next} id={"register-myrole-next-button"}/>
    </div>
  </div>
);}

export default MyRole;
