import React from 'react';
import styles from './EmailField.module.scss';
import useBroadcast from '../../hooks/useBroadcast';

const EmailField = (params: any) => {
  const broadcast = useBroadcast();

  const broadcastChatClubs = () => {
    //checking to see if the word chat is in error msg for broadcast 
    let notAChatLink = params?.emailField?.error.includes('chat') ? true : false;
    if (params?.referrer === 'clubs' && params?.chat === '1' && notAChatLink) {
      //use broadcast on click
      broadcast({
        status: 'CHAT',
        data: {}
      }, params.referrer);
      console.log({ status: 'CHAT', data: {}});
    }
  }

  return (
    <div className={styles.emailContainer}>
      <div className={styles.label}>Email Address</div>
      <form id="login-form" onSubmit={e => { e.preventDefault();}}>
        <input className={`${styles.inputGrey} ${params.emailField.error === '' || !params.touched ? '' : styles.errorField}`}
          id={params.id}
          autoComplete="on"
          aria-label="enter email"
          type="email"
          name="email"
          autoFocus
          value = {params.emailField.email}
          onClick={() => params.setTouched(true)}
          onChange={(e) => {
            if(params.touched){
              params.setEmailField(params.handleInput('email', e.target.value, params.emailField));
            } else {
              params.setTouched(true);
              params.setEmailField(params.handleInput('email', e.target.value, params.emailField));
            }
          }}
        />
        <input type="password" name="password" className={styles.hidden} />
        {params.emailField.error !== '' && params.touched &&
          <div
          // params.emailField.error only comes from 3 msgConfig in interface, it should NEVER comes from user input
            dangerouslySetInnerHTML={{__html: params.emailField.error}}
            data-testid="emailErr" 
            className={styles.errorMessage} 
            onClick={broadcastChatClubs}>
          </div>}
        </form>
    </div>
  )
}

export default EmailField;