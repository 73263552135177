import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useLogout = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const logoutResponse = async () => {
    return await makeApiRequest(`${apiBase}/api/logout`, {});
  };

  return [logoutResponse];
}

export default useLogout;