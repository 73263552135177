import dumbelDataProps from '../interfaces/dumbleDataProps';

let dumbleData: dumbelDataProps = <dumbelDataProps>{
  domain: {
    audience: 'Enterprise',
    channel: 'Enterprise',
    experienceType: 'Tools'
  },
  page: {
    name: '',
    type: '',
    siteSectionLevel1: ''
  },
  user: {
    loginStatus: true,
    userId: '',
    userType: ''
  },
  error: {
    errorType: ''
  },
  login: {
    page: {
      name: ''
    }
  }
};

const updateDumbleDataPage = (fieldValue: object) => {
  Object.assign(dumbleData['page'], fieldValue);

  return dumbleData;
}

const updateDumbleDataUser = (fieldValue: object) => {
  Object.assign(dumbleData['user'], fieldValue);

  return dumbleData;
}

const updateDumbleDataError = (fieldValue: object) => {
  Object.assign(dumbleData['error'], fieldValue);

  return dumbleData;
}

const updateDumbleDataLogin = (fieldValue: object) => {
  Object.assign(dumbleData['login'], fieldValue);

  return dumbleData;
}
  
const useDumbleData = (section?: string, data?: object) => {
  // update section with data for each field otherwise its a regular dumble data init 
  if (data) {
    if (section === 'page'){
      updateDumbleDataPage(data);
    } else if (section  === 'user'){
      updateDumbleDataUser(data);
    } else if (section  === 'login'){
      updateDumbleDataLogin(data);
    } 
     else {
      updateDumbleDataError(data);
    }
  }
  (window as any).dumbleData = dumbleData;
  return dumbleData;
}
  
export default useDumbleData;