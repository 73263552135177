// this util functions maps two different 403 feign exception coming from /api/login
// one to invalid credential (to status 403) and one to locked account (to status 418)
// TODO - handle this on service side with proper exceptions
const mapPasswordError = (feignMessage: string, errorMessage: any) => {
    let obj = JSON.parse(errorMessage);
    if(obj.message === feignMessage){
        return 418;
    } else {
        return 403;
    }
}

// this util function maps 403 locked account error on POST /api/request-reset-password to 418,
// to keep consistent with mapping explained above
const mapForgotPasswordError = (status: number) => {
    let mappedStatus = status;
    if(status === 403){
        mappedStatus = 418;
    }
    return mappedStatus;
}

export { mapPasswordError, mapForgotPasswordError };