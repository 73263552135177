export const GRADES = [
  {
    label: "Pre-Kindergarten",
    value: "pre_k",
    gradeRole: "pkt",
  },
  {
    label: "Kindergarten",
    value: "kg",
    gradeRole: "kt",
  },
  {
    label: "1st Grade",
    value: "first",
    gradeRole: "fgt",
  },
  {
    label: "2nd Grade",
    value: "second",
    gradeRole: "sgt",
  },
  {
    label: "3rd Grade",
    value: "third",
    gradeRole: "tgt",
  },
  {
    label: "4th Grade",
    value: "fourth",
    gradeRole: "fogt",
  },
  {
    label: "5th Grade",
    value: "fifth",
    gradeRole: "figt",
  },
  {
    label: "6th Grade",
    value: "sixth",
    gradeRole: "sigt",
  },
  {
    label: "7th Grade",
    value: "seventh",
    gradeRole: "segt",
  },
  {
    label: "8th Grade",
    value: "eighth",
    gradeRole: "egt",
  },
  {
    label: "9th Grade",
    value: "ninth",
    gradeRole: "ngt",
  },
  {
    label: "10th Grade",
    value: "tenth",
    gradeRole: "tegt",
  },
  {
    label: "11th Grade",
    value: "eleventh",
    gradeRole: "elgt",
  },
  {
    label: "12th Grade",
    value: "twelfth",
    gradeRole: "twgt",
  },
];
