const isUpgradeable  = (ref: string) => {
    switch(ref){
        case 'tso':
        case 'tsocheckout':
        case 'tsoprntquote':
            return true;
        default:
            return false;
    }
}

export { isUpgradeable  };