import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './SignIn.module.scss';
import EmailField from '../../../components/EmailField/EmailField';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useDebounce from '../../../hooks/useDebounce';
import validate from '../../../utils/validate';
import useVerifyEmail from '../../../hooks/useVerifyEmail';
import { ConfigContext } from '../../../providers/ConfigContext';
import emailFieldProps from '../../../interfaces/emailFieldProps';
import isBaseline from '../../../utils/is-baseline';
import isPartner from '../../../utils/is-partner';
import useBroadcast from '../../../hooks/useBroadcast';
import useRefRedirect from '../../../hooks/useRefRedirect';
import refProps from '../../../interfaces/refProps';
import clubsMsg from '../../../interfaces/clubsMsgConfig';
import sdmMsg from '../../../interfaces/sdmMsgConfig';
import baselineMsg from '../../../interfaces/baselineMsgConfig';
import close from '../../../styles/images/close.svg';
import useDumbleData from '../../../hooks/useDumbleData';
import dumbleDataProps from '../../../interfaces/dumbleDataProps';
import deleteSCHLCookies from '../../../utils/delete-scholastic-cookies';
import Spinner from '../../../components/Spinner/Spinner';
import { detect400BadRequest } from '../../../utils/detect-specific-string-error';
import schlAuthJWT from '../../../interfaces/schlAuthJWT';
import decodeJWT from '../../../utils/decode-jwt';
import getCookieValue from '../../../utils/get-cookie-value';
import isDirect from '../../../utils/is-direct';
import isCustomReg from '../../../utils/is-customReg';

declare var grecaptcha: any;
declare global {
  interface Window { _satellite: any; }
}

window._satellite = window._satellite || {};

const SignIn = (props: refProps) => {
  const location = useLocation<emailFieldProps>();
  const { reCaptchaV3SiteKey } = useContext(ConfigContext);
  const { aemUrl } = useContext(ConfigContext);
  const reCaptchaV3DomId: string = 'reCaptchaV3-siteKey';
  const emailDebouncer = useDebounce();
  const [verifyEmail] = useVerifyEmail();
  const [isDisabled, setIsDisabled] = useState(true);
  const [status, setStatus] = useState(0);
  const [reCaptchaToken, setReCaptchaToken] = useState('');
  const [badRequestTry, setBadRequestTry] = useState(0);
  const [hideRegistration, setHideRegistration] = useState(false);
  const broadcast = useBroadcast();
  const refRedirect = useRefRedirect();
  const { referrer, chat, reg, ignore } = props;
  let history = useHistory();
  const [emailField, setEmailField] = useState({
    email: (location.state === undefined) ? '' : location.state.emailField.email,
    error: 'Please enter a properly formatted email address.',
  });

  const [touched, setTouched] = useState(false);
  let [inActiveAccErrorMsg, setInactiveMsg] = useState("");
  let [notFoundAccErrorMsg, setNotFoundMsg] = useState("");
  let [unavailableErrorMsg, setUnavailableErrorMsg] = useState("");
  let [tokenErrorMsg, setTokenErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;
  let siteKey: string = reCaptchaV3SiteKey;

  // # of tries for 400 bad request handling until generic error message is displayed
  const badRequestTryLimit: number = 3;
  useMemo(() => {
    if (referrer === 'clubs') {
      setNotFoundMsg(clubsMsg.emailApiError);
      setUnavailableErrorMsg(clubsMsg.unavailableError);
      if (chat === '1') {
        setInactiveMsg(clubsMsg.emailInactiveChatOn);
      } else {
        setInactiveMsg(clubsMsg.emailInactiveChatOff);
      }
    } else if (referrer === 'sdm') {
      setNotFoundMsg(sdmMsg.emailApiError);
      setInactiveMsg(sdmMsg.emailInactive);
      setUnavailableErrorMsg(sdmMsg.unavailableError);
    } else {
      setNotFoundMsg(baselineMsg('emailApiError', referrer));
      setInactiveMsg(baselineMsg('emailInactive', referrer));
      setUnavailableErrorMsg(baselineMsg('unavailableError', referrer));
    }
  }, [referrer, notFoundAccErrorMsg, inActiveAccErrorMsg, unavailableErrorMsg, chat]);

  useEffect(() => {
    let timeout: any; 
    //remove field validations for Sentinel One Scan
    if (ignore != undefined && ignore === 'true'){
      timeout = emailDebouncer(
        setEmailField.bind(
          SignIn,
          handleInput('error', validate(emailField.email, []), emailField)
        ),
        300
      )
    } else { 
      //Regular field validations
      timeout = emailDebouncer(
        setEmailField.bind(
          SignIn,
          handleInput('error', validate(emailField.email, ['required','emailFormat']), emailField)
        ),
        300
      )
    }
    
    return () => clearTimeout(timeout);
  }, [emailField.email]);

  useEffect(() => {
    // add reCaptcha to enterprise.js only when it doesn't exist
    const scriptExists = document.getElementById(reCaptchaV3DomId);

    if (!scriptExists) {
      const script = document.createElement("script")
      script.id = reCaptchaV3DomId;
      script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + reCaptchaV3SiteKey;
      document.head.appendChild(script)
    }
  }, []);

  //when we have a bad token, set it to empty and make the api request
  //if recaptcha check is disabled, we can skip the check when token is empty
  const handleBadToken = () => {
    setReCaptchaToken('');
    requestVerifyEmail();
  }

  useEffect(() => {
    disableReg();
    let pageData = {
      name: 'MyScholastic:Login:Step1:Email',
      type: 'Login',
      siteSectionLevel1: 'MyScholastic:Login:Step1:Email'
    };
    dumbledata = dumbleDataHook('page', pageData);
  }, []);

  const handleReCaptchaV3 = () => {
    return new Promise(() => {
      grecaptcha.enterprise.ready(function () {
        try {
          // never change/modify action on its own, action should always match its consul value from MyScholastic Service
          grecaptcha.enterprise.execute(siteKey.trim(), { action: 'email_verify' }).then((token: string) => {
            setReCaptchaToken(token);
          })
        } catch {
          handleBadToken()
        }
      });
    });
  }

  useEffect(() => {
    if (reCaptchaToken) {
      setStatus(0);
      requestVerifyEmail();
    }
  }, [reCaptchaToken])

  useEffect(() => {
    switch (status) {
      case 0:
        break;
      case 400:
        // this 400 case happens with unknown, possibly invalid cookie conflict in browser causing 400 bad request
        // handle it by removing all previously-set scholastic cookie and making api call again
        // until badRequestTryLimit has reached. After that, show generic message.
        let numberOfTries = badRequestTry;
        setBadRequestTry(numberOfTries + 1);
        break;
      case 401:
        setEmailField(handleInput('error', tokenErrorMsg, emailField));
        break;
      case 403:
        setEmailField(handleInput('error', inActiveAccErrorMsg, emailField));
        break;
      case 404:
        setEmailField(handleInput('error', notFoundAccErrorMsg, emailField));
        break;
      case 200:
        setEmailField(handleInput('error', '', emailField));
        break;
      default:
        setEmailField(handleInput('error', unavailableErrorMsg, emailField));
        break;
    }
  }, [status])

  useEffect(() => {
    if (badRequestTry <= badRequestTryLimit && badRequestTry !== 0) {
      console.log("trying to handle 400 bad request...");
      handleBadRequest();
    } else if (badRequestTry > badRequestTryLimit) {
      // setting it to 500 to show generic error message after try limit has reached
      setStatus(500);
    }
  }, [badRequestTry])

  const handleBadRequest = () => {
    deleteSCHLCookies();
    handleReCaptchaV3();
  }

  const requestVerifyEmail = async () => {
    setIsLoading(true);
    const { data, error } = await verifyEmail(emailField.email, reCaptchaToken);
    if (error) {
      setIsLoading(false);
      if (error.status == 500 && detect400BadRequest(error.message)) {
        setStatus(400);
      } else {
        setStatus(error.status);
      }
      console.error('Error verifying email', error.status);
      let err = {
        errorType: error.status
      };
      dumbledata = dumbleDataHook('error', err);
      return;
    } else {
      setIsLoading(false);
      //decoding token before redirect to submit password to check if cookie has been set correctly
      let token: schlAuthJWT;
      token = decodeJWT(getCookieValue('SCHL_AUTH'));
      if (!token.payload) {
        setTokenErrorMsg(baselineMsg('parseTokenError', referrer));
        setStatus(401);
      } else {
        setStatus(200);
        history.push({
          pathname: '/sign-in/submit-password.html',
          state: {
            email: emailField.email,
            token: token
          }
        });
      }
    }
  }

  const redirectToRegister = () => {
    if((isBaseline(referrer) && referrer != "cptk") || (isDirect(referrer) && !isCustomReg(referrer))|| referrer == "usor"){ //uncomment to redirect internally
      // call _satellite track events for the referrer "tsoprntquote"
      if (referrer === 'tsoprntquote' && window._satellite.buildInfo !== undefined) {
        console.log("_satellite buildInfo before calling _satellite.track");
        console.log(window._satellite.buildInfo);
        window._satellite.track('register-popup-print-quote')
        console.log("_satellite.track event: register-popup-print-quote called");
        console.log("_satellite buildInfo after calling _satellite.track");
        console.log(window._satellite.buildInfo);
      }
      history.push({
        pathname: '/register/my-info.html'
      });
    }
    /*else if(referrer == "cptk"){
      history.push({
        pathname: '/register/bfc.html'
      })
    }*/
    else{ 
      if (isBaseline(referrer)) {
        broadcast({
          status: 'REDIRECT',
          data: {
            [referrer]: refRedirect(referrer)
          },
          callbacks: []
        }, referrer);
        console.log({ status: 'REDIRECT', data: { [referrer]: refRedirect(referrer) }, callbacks: [] });
      } else {
        broadcast({
          status: 'REDIRECT',
          data: {
            [referrer]: refRedirect(referrer)
          }
        }, referrer);
        console.log({ status: 'REDIRECT', data: { [referrer]: refRedirect(referrer) } });
    }}
  }

  useEffect(
    () => {
      setIsDisabled(emailField.error !== '');
    }, [emailField]
  );

  const handleInput = ((type: string, input: any, field: object) => {
    const newField: any = { ...field };
    newField[type] = input;
    return newField;
  });

  const cancel = () => {
    if (isBaseline(referrer)) {
      broadcast({
        status: 'CLOSE',
        data: {},
        callbacks: ['myScholasticOnLoginClose']
      }, referrer);
      console.log({ status: 'CLOSE', data: {}, callbacks: ['myScholasticOnLoginClose'] });
    } else {
      broadcast({
        status: 'CLOSE',
        data: {}
      }, referrer);
      console.log({ status: 'CLOSE', data: {} });
    }
  }

  useEffect(() => {
    const listener = (event: { code: string; }) => {
      if (event.code === "Enter" && emailField.error == '') {
        handleReCaptchaV3();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [emailField])

  const disableReg = () => {
    //reg: 0 -> hide register CTA
    //reg: 1 -> show register CTA
    if ((referrer === 'clubs' || referrer === 'storyvoice') && reg === '0') {
      setHideRegistration(true)
    }
  }

  return (
    <div role="main">

      {isLoading && <div>
        <div className={styles.spinnerModal}>
          <div className={styles.spinnerPosition}>
            <Spinner />
          </div>
        </div>
      </div>}

      <img src={close} tabIndex={1} alt="close icon" className={`${styles.close} ${isBaseline(referrer) ? styles.disable : ''}`} onClick={cancel} />

      <h1 className={styles.heading}>Sign In</h1>
      {isPartner(referrer) && <div className={styles.parentsMsg}>To reset your password, first enter your email address, click Continue, then click "Forgot Password?"</div>}
      <EmailField setEmailField={setEmailField} emailField={emailField} handleInput={handleInput} referrer={referrer} chat={chat} id={"user-text-field"} touched={touched} setTouched={setTouched} />
      <div className={styles.buttonContainer}>
        <SubmitButton outlined={false} name={'continue'} isDisabled={isDisabled} submitFunction={handleReCaptchaV3} id={"signin-email-submit-button"} />
      </div>

      <div><hr className={styles.lineBreak} /></div>
      {!hideRegistration &&
        <div>
          <h1 className={styles.heading}>Create an Account</h1>
          <div className={styles.buttonContainer}>
            <SubmitButton outlined={true} name={'register'} submitFunction={redirectToRegister} id={"signin-register-button"} />
          </div>
        </div>}
    </div>

  )
}

export default SignIn;
