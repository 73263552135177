import { useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigContext } from '../providers/ConfigContext';

const useBroadcast = () => {
  const appConfig = useContext(ConfigContext);
  const location = useLocation();

  return useCallback((message: any, ref: string) => {
    if (ref){
      const { origin } = appConfig[ref];
      const targetOrigins = [...origin?.split(',')];
      targetOrigins.forEach((targetOrigin: string) => {
        if (targetOrigin) {
          window.parent.postMessage(JSON.stringify(message), targetOrigin);
        }
      });
    // TODO - remove below debug log when not needed
    } else {
      console.log('broadcast not ran due to invalid ref: ' + ref);
    }
  }, [appConfig]);
}

export default useBroadcast;