import React, { useState, useEffect, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { Link, useHistory, useLocation } from "react-router-dom";
import usePasswordSubmit from "../../../hooks/usePasswordSubmit";
import styles from "./SubmitPassword.module.scss";
import useBroadcast from "../../../hooks/useBroadcast";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import emailProps from "../../../interfaces/emailProps";
import { ConfigContext } from "../../../providers/ConfigContext";
import useRequestPasswordResetApi from "../../../hooks/requestPasswordReset";
import isBaseline from "../../../utils/is-baseline";
import refProps from "../../../interfaces/refProps";
import {
  mapPasswordError,
  mapForgotPasswordError,
} from "../../../utils/map-password-error";
import clubsMsg from "../../../interfaces/clubsMsgConfig";
import sdmMsg from "../../../interfaces/sdmMsgConfig";
import baselineMsg from "../../../interfaces/baselineMsgConfig";
import close from "../../../styles/images/close.svg";
import { isEducator, isEducatorRef } from "../../../utils/is-educator";
import { isUpgradeable } from "../../../utils/is-upgradeable";
import useLogout from "../../../hooks/useLogout";
import useDumbleData from "../../../hooks/useDumbleData";
import dumbleDataProps from "../../../interfaces/dumbleDataProps";
import Spinner from "../../../components/Spinner/Spinner";

declare var grecaptcha: any;
declare global {
  interface Window {
    _satellite: any;
  }
}

window._satellite = window._satellite || {};

const SubmitPassword = (props: refProps) => {
  const location = useLocation<emailProps>();
  const [email, setEmail] = useState(location.state.email);
  const [status, setStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [showReCaptcha, setShowReCaptcha] = useState(true); //set to false to test locally
  const [submitPassword] = usePasswordSubmit();
  const [logout] = useLogout();
  const broadcast = useBroadcast();
  let history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const { reCaptchaSiteKey, myschlapibase } = useContext(ConfigContext);
  const [resetPassword] = useRequestPasswordResetApi();
  const skipEmail: boolean = false;
  const [showForgotPasswordLink, setShowForgotPasswordLink] = useState(true);
  const { referrer, chat, reg, ignore } = props;
  const dumbleDataHook = useDumbleData;
  const reCaptchaV2DomId: string = "reCaptchaV2-script";
  let dumbledata: dumbleDataProps;
  const [isLoading, setIsLoading] = useState(false);

  const pageData = {
    name: "MyScholastic:Login:Step2:Password",
    type: "Login",
    siteSectionLevel1: "MyScholastic:Login:Step2:Password",
  };

  useEffect(() => {
    dumbledata = dumbleDataHook("page", pageData);
  }, []);

  useEffect(() => {
    // add reCaptcha V2 enterprise script only when it doesn't exist
    const scriptExists = document.getElementById(reCaptchaV2DomId);

    if (!scriptExists) {
      const script = document.createElement("script");
      script.id = reCaptchaV2DomId;
      script.src = "https://www.google.com/recaptcha/enterprise.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, []);

  const submitCredentials = async () => {
    setIsLoading(true);
    let loginDumble = { page: { name: referrer } };
    const { data, error } = await submitPassword(
      email,
      password,
      reCaptchaToken,
      referrer
    );
    if (error) {
      setIsLoading(false);
      if (error.status === 403) {
        setStatus(mapPasswordError("User account is locked.", error.message));
      } else {
        setStatus(error.status);
      }
      let err = {
        errorType: error.status,
      };
      dumbledata = dumbleDataHook("error", err);
      return;
    } else {
      let user = {
        loginStatus: true,
        userId: "",
        userType: "",
      };
      if (isEducatorRef(referrer)) {
        if (isEducator(data)) {
          setStatus(200);
          successBroadcast(data, referrer);
          user.userType = "Educator";
          user.userId = data?.user?.id;
          dumbledata = dumbleDataHook("user", user);
          dumbledata = dumbleDataHook("page", pageData);
          dumbledata = dumbleDataHook("login", loginDumble);
          if (window._satellite.buildInfo !== undefined) {
            window._satellite.track("login-successful");
          }
          //if referrer is TSO, do not logout, so that on redirection, 
          //My Scholastic role upgrade modal is displayed
        }else if(isUpgradeable(referrer)){
          setIsLoading(false);
          setStatus(409);
        }else {
          setIsLoading(false);
          await logout();
          setStatus(409);
        }
      } else {
        setStatus(200);
        successBroadcast(data, referrer);
        user.userType = "Parent";
        user.userId = data?.user?.id;
        dumbledata = dumbleDataHook("user", user);
        dumbledata = dumbleDataHook("page", pageData);
        dumbledata = dumbleDataHook("login", loginDumble);
        if (window._satellite.buildInfo !== undefined) {
          window._satellite.track("login-successful");
        }
      }
    }
  };

  useEffect(() => {
    if (showReCaptcha) {
      setDisabled(password.length <= 0 || reCaptchaToken === "");
    } else {
      setDisabled(password.length <= 0);
    }
    //enable submit button with ignore flag for Sentinel One
    if(ignore != undefined && ignore === 'true'){
      setDisabled(false);
    }
  }, [password, reCaptchaToken, showReCaptcha, ignore]);


  const clear = (includeFields: boolean) => {
    if (showReCaptcha) {
      // Reset reCaptcha on clear
      (window as any).grecaptcha.enterprise.reset();
      setReCaptchaToken("");
    }

    if (includeFields) {
      setPassword("");
      setStatus(0);
    }
  };

  useEffect(() => {
    let errorMsgConfig: any = {};
    if (referrer === "clubs") {
      errorMsgConfig = clubsMsg;
    } else if (referrer === "sdm") {
      errorMsgConfig = sdmMsg;
    } else {
      errorMsgConfig.passwordApiError = baselineMsg(
        "passwordApiError",
        referrer
      );
      errorMsgConfig.passwordPolicyError = baselineMsg(
        "passwordPolicyError",
        referrer
      );
      errorMsgConfig.passwordLocked = baselineMsg("passwordLocked", referrer);
      errorMsgConfig.passwordEducatorError = baselineMsg(
        "passwordEducatorError",
        referrer
      );
      errorMsgConfig.unavailableError = baselineMsg(
        "unavailableError",
        referrer
      );
    }
    switch (status) {
      case 0: {
        break;
      }
      case 400: {
        // Something is invalid with reCaptcha
        setErrorMessage("Please re-verify with reCaptcha.");
        clear(false);
        break;
      }
      case 403: {
        setErrorMessage(errorMsgConfig?.passwordApiError);
        clear(true);
        break;
      }
      case 423: {
        setErrorMessage(errorMsgConfig?.passwordPolicyError);
        clear(true);
        break;
      }
      case 409: {
        setErrorMessage(errorMsgConfig?.passwordEducatorError);
        setShowForgotPasswordLink(false);
        clear(true);
        break;
      }
      case 418: {
        let lockedError: any;
        if (chat === "1") {
          lockedError = errorMsgConfig?.passwordLockedChatOn;
        } else if (chat === "0") {
          lockedError = errorMsgConfig?.passwordLockedChatOff;
        } else {
          lockedError = errorMsgConfig?.passwordLocked;
        }
        setErrorMessage(lockedError);
        setShowForgotPasswordLink(false);
        clear(true);
        break;
      }
      case 200: {
        setErrorMessage("");
        clear(true);
        // call _satellite track events for the referrer "tsoprntquote"
        if (
          referrer === "tsoprntquote" &&
          window._satellite.buildInfo !== undefined
        ) {
          console.log("_satellite buildInfo before calling _satellite.track");
          console.log(window._satellite.buildInfo);
          window._satellite.track("signin-popup-print-quote");
          console.log(
            "_satellite.track event: signin-popup-print-quote called"
          );
          console.log("_satellite buildInfo after calling _satellite.track");
          console.log(window._satellite.buildInfo);
        }
        break;
      }
      default: {
        setErrorMessage(errorMsgConfig?.unavailableError);
        clear(true);
      }
    }
  }, [status, broadcast, referrer]);

  useEffect(() => {
    // decode JWT, comment out for local testing
    if (location.state.token.payload) {
      setShowReCaptcha(location.state.token.payload.captcha_required);
    } else {
      //error parsing token. Redirect user to login screen
      history.push({
        pathname: "/sign-in.html",
        state: {
          emailField: {
            email: email,
            error: baselineMsg("parseTokenError", referrer),
          },
        },
      });
    }
  }, []);

  const requestResetPassword = async () => {
    //this api call will pass email to MSS to send a password resent link to the email with a token
    const { data, error } = await resetPassword(email, skipEmail, referrer);
    if (error) {
      console.error("Error Requesting Password reset", error.status);
      setStatus(mapForgotPasswordError(error.status));
      return;
    } else {
      //email service needs to be implemented with token
      console.log("data", status, data);
      setStatus(200);
      if (isBaseline(referrer)) {
        broadcast(
          {
            status: "RESET",
            data: {},
            callbacks: ["myScholasticOnLoginClose"],
          },
          referrer
        );
        console.log({
          status: "RESET",
          data: {},
          callbacks: ["myScholasticOnLoginClose"],
        });
      } else {
        broadcast(
          {
            status: "RESET",
            data: {},
          },
          referrer
        );
        console.log({ status: "RESET", data: {} });
      }
      history.push({
        pathname: "/sign-in/successful-reset.html",
        state: email,
      });
      //redirect to check email page passing email so user can retry
    }
  };

  const successBroadcast = (data: any, ref: string) => {
    if (isBaseline(ref)) {
      broadcast(
        {
          status: "SUCCESS",
          data: data,
          callbacks: ["myScholasticOnLoginClose", "myScholasticOnLoginSuccess"],
        },
        ref
      );
      console.log({
        status: "SUCCESS",
        data: data,
        callbacks: ["myScholasticOnLoginClose", "myScholasticOnLoginSuccess"],
      });
    } else {
      broadcast(
        {
          status: "SUCCESS",
          data: data,
        },
        ref
      );
      console.log({ status: "SUCCESS", data: data });
    }
  };

  const broadcastChatClubs = () => {
    //checking to see if the word chat is in error msg for broadcast
    let notAChatLink = errorMessage?.includes("chat") ? true : false;
    if (referrer === "clubs" && chat === "1" && notAChatLink) {
      //use broadcast on click
      broadcast(
        {
          status: "CHAT",
          data: {},
        },
        referrer
      );
      console.log({ status: "CHAT", data: {} });
    }
    //checking to see if referrer is upgradable and then redirect to
    //My Scholastic account page to upgrade role.
    if(isUpgradeable(referrer) && errorMessage?.includes("The Teacher Store")){
      const redirectURL = myschlapibase.origin+'/my-scholastic/profile/my-profile.html?upgrade=true';
      window.open(redirectURL, '_blank', 'noopener,noreferrer');
    }
  };

  const cancel = () => {
    if (isBaseline(referrer)) {
      broadcast(
        {
          status: "CLOSE",
          data: {},
          callbacks: ["myScholasticOnLoginClose"],
        },
        referrer
      );
      console.log({
        status: "CLOSE",
        data: {},
        callbacks: ["myScholasticOnLoginClose"],
      });
    } else {
      broadcast(
        {
          status: "CLOSE",
          data: {},
        },
        referrer
      );
      console.log({ status: "CLOSE", data: {} });
    }
  };

  useEffect(() => {
    const listener = (event: { code: string }) => {
      if (event.code === "Enter" && !disabled) {
        submitCredentials();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [password]);

  return (
    <div className={styles.container}>
      {isLoading && (
        <div>
          <div className={styles.spinnerModal}>
            <div className={styles.spinnerPosition}>
              <Spinner />
            </div>
          </div>
        </div>
      )}
      <img
        src={close}
        alt="close icon"
        className={`${styles.close} ${
          isBaseline(referrer) ? styles.disable : ""
        }`}
        onClick={cancel}
      />
      <div className={styles.headerText}>Sign In</div>
      <form
        id="login-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={styles.emailContainer}>
          <div id="username-value">{email}</div>
          <input
            id="username"
            autoComplete="username"
            value={email}
            className={styles.hidden}
            readOnly
          ></input>
          <Link
            to={{
              pathname: "/sign-in.html",
              state: {
                emailField: {
                  email: email,
                  error: "",
                  touched: false,
                },
              },
            }}
          >
            <div id="signin-change-email" className={styles.change}>
              change
            </div>
          </Link>
        </div>
        <div className={styles.labelContainer}>
          <div className={styles.label}>Password</div>
        </div>
        <div className={styles.inputContainer}>
          <input
            id="password-text-field"
            aria-label="enter password"
            autoComplete="current-password"
            type="password"
            name="password"
            autoFocus
            value={password}
            className={styles.inputGrey}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          {errorMessage && (
            <div
              // errorMessage only comes from 3 msgConfig in interface, it should NEVER comes from user input
              dangerouslySetInnerHTML={{ __html: errorMessage }}
              data-testid="passwordErr"
              className={styles.errorMessage}
              onClick={broadcastChatClubs}
            ></div>
          )}
        </div>
      </form>

      {showReCaptcha && (
        <div className={styles.reCaptcha}>
          <ReCAPTCHA
            sitekey={reCaptchaSiteKey}
            onChange={(token: any) => setReCaptchaToken(token || "")}
            // hide checkbox reCaptcha and send empty token in case of technical failure to allow login
            onErrored={() => setShowReCaptcha(false)}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <SubmitButton
          isDisabled={disabled}
          name={"sign in"}
          submitFunction={submitCredentials}
          id={"password"}
        />
      </div>
      <div className={styles.forgotLink}>
        {showForgotPasswordLink && (
          <div
            id="signin-forgot-password-link"
            tabIndex={0}
            className={styles.link}
            onClick={requestResetPassword}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                requestResetPassword();
              }
            }}
          >
            Forgot password?
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmitPassword;