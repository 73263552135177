import { child } from "../interfaces/child"
import { grade } from "../interfaces/grade"
import registerRequest from "../interfaces/registerRequest"

const buildRegisterBody = (firstName: string, lastName: string, password: string, email: string, school: string,
    role?:string, position?:string, grades?:grade[]) =>{
    let registerRequestBody: registerRequest = {
        credentials: {
            username: email,
            password: password
        },
        basicProfile: {
            email: email,
            firstName: firstName,
            lastName: lastName
        }
    }
    if(role != "parent"){
        registerRequestBody.personas = {
            educator:{
                school: {
                    schoolId: "",
                    gradeRoles: []
                }
            }
        }
        if(grades != null && registerRequestBody?.personas?.educator?.school != null){
            for(let i = 0; i < grades.length; i++){
                switch (grades[i].gradeRole) {
                    case "pkt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ pre_k: {classSize: 0}}};
                        break;
                    case "kt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ kg: {classSize: 0}}};
                        break;
                    case "fgt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ first: {classSize: 0}}};
                        break;
                    case "sgt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ second: {classSize: 0}}};
                        break;
                    case "tgt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ third: {classSize: 0}}};
                        break;
                    case "fogt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ fourth: {classSize: 0}}};
                        break;       
                    case "figt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ fifth: {classSize: 0}}};
                        break;
                    case "sigt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ sixth: {classSize: 0}}};
                        break;
                    case "segt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ seventh: {classSize: 0}}};
                        break;
                    case "egt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ eighth: {classSize: 0}}};
                        break;
                    case "ngt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ ninth: {classSize: 0}}};
                        break;
                    case "tegt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ tenth: {classSize: 0}}};
                        break;
                    case "elgt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ eleventh: {classSize: 0}}};
                        break;
                    case "twgt":
                        if(registerRequestBody.personas.educator.school != null) registerRequestBody.personas.educator.school = { ...registerRequestBody.personas.educator.school.classes, classes :{ twelfth: {classSize: 0}}};
                        break;
                    default:
                        break;
                }
            }
            registerRequestBody.personas.educator.school.gradeRoles = [];
            for (let i = 0; i < grades.length; i++) {
                registerRequestBody.personas.educator.school.gradeRoles?.push(grades[i].gradeRole);               
            }
            if(school != null && registerRequestBody?.personas?.educator?.school != null) {                
                registerRequestBody.personas.educator.school.schoolId = school;
                console.log(registerRequestBody.personas.educator.school.schoolId);
            }
            if(position != null && position != "" && registerRequestBody?.personas?.educator?.school != null){
                registerRequestBody.personas.educator.school.positions = [position];
            }
        }
    } 
    return registerRequestBody;
}
export default buildRegisterBody