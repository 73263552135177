import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { createNull } from "typescript";
import Spinner from "../../../components/Spinner/Spinner";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import useDebounce from "../../../hooks/useDebounce";
import useLookupApi from "../../../hooks/useLookupApi";
import refProps from "../../../interfaces/refProps";
import { school } from "../../../interfaces/school";
import { customStyles } from "../../../utils/select-custom-styles";
import validate from "../../../utils/validate";
import MyKids from "../MyKids/MyKids";
import SchoolBuilder from "../../../components/SchoolBuilder/SchoolBuilder";
import styles from "./MySchool.module.scss";
import useRegisterApi from "../../../hooks/useRegisterApi";
import useAddKid from "../../../hooks/useAddKidApi";
import buildRegisterBody from "../../../utils/register-body-builder";
import useGetCountries from "../../../hooks/useGetCountries";
import useDumbleData from "../../../hooks/useDumbleData";
import dumbleDataProps from "../../../interfaces/dumbleDataProps";

interface MySchoolProps {}

declare global {
  interface Window { _satellite: any; }
}

window._satellite = window._satellite || {};

const MySchool = (props: refProps) => {
  const { referrer, chat, reg } = props;
  const [schoolType, setSchoolType] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [schoolSelectPhase, setSchoolSelectPhase] = useState(true); //schoolSelectPhase is the school type selection process
  const [schoolFindPhase, setSchoolFindPhase] = useState(false); //schoolFindPhase is school finding, the dropdowns for picking a school with us, non-us, mil
  const [schoolBuildPhase, setSchoolBuildPhase] = useState(false); //schoolBuildPhase is for home schools or 'cant find your school?'
  const [schoolList, setSchoolList] = useState<any[]>([]);
  const [schoolData, setSchoolData] = useState<school[]>([]);
  const zipcodeDebouncer = useDebounce();
  const [status, setStatus] = useState(200);
  const [isLoading, setIsLoading] = useState(false);
  const [zipcode, setZipcode] = useState({
    value: "",
    error: "",
    touched: false,
  });
  const [country, setCountry] = useState("");
  const [lookUpSchools] = useLookupApi();
  let history = useHistory();
  const location = useLocation<any>();  
  const [school, setSchool] = useState<school>(location.state?.school);

  const [firstName, setFirstName] = useState(location.state?.firstName);
  const [lastName, setLastName] = useState(location.state?.lastName);
  const [email, setEmail] = useState(location.state?.email);
  const [password, setPassword] = useState(location.state?.password);
  const [role, setRole] = useState(location.state?.role);
  const [grades, setGrades] = useState(location.state?.grades);
  const [position, setPosition] = useState(location.state?.position);
  const [registerUser] = useRegisterApi();
  const [getCountries] = useGetCountries();
  const [addKid] = useAddKid();
  const [countries, setCountries] = useState<any[]>([]);
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;


  useEffect(() => {
    let pageData = {
      name: "MyScholastic:Registration:My School",
      type: "Registration",
      siteSectionLevel1: "MyScholastic:Registration:My School",
    };
    dumbledata = dumbleDataHook("page", pageData);
  }, []);

  const registerInfo =
    //to test locally, increment a number
    {
      credentials: {
        username: "regtest12561@schl.com",
        password: "passw0rd",
      },
      basicProfile: {
        email: "regtest12561@schl.com",
        firstName: "Test",
        lastName: "Test",
      },
    };

  const handleInput = (type: string, input: any, field: object) => {
    const newField: any = { ...field };
    newField[type] = input;
    return newField;
  };

  const prevHandler = () => {
    if (schoolSelectPhase) {
      history.push({
        pathname: "/register/my-role.html",
        state: {
          password: location.state?.password,
        },
      });
    } else if (schoolFindPhase) {
      setZipcode({
        value: "",
        error: "",
        touched: false,
      });
      setSchoolList([]);
      setSchoolData([]);
      setCountry("");
      setStatus(200);
      setSchoolFindPhase(false);
      setSchoolSelectPhase(true);
    } else if (schoolBuildPhase) {
      setSchoolBuildPhase(false);
      setSchoolSelectPhase(true);
    }
  };

  const nextHandler = () => {
    if (
      schoolSelectPhase &&
      (schoolType == "ms" || schoolType == "us" || schoolType == "is")
    ) {
      setSchoolSelectPhase(false);
      setSchoolFindPhase(true);
    } else if (schoolSelectPhase && schoolType == "hs") {
      setSchoolSelectPhase(false);
      setSchoolBuildPhase(true);
    } else if (schoolFindPhase) {
      register();
    }
  };

  const register = async () => {
    if (school != null) {
      console.log(school);
      const { data, error } = await registerUser(
        buildRegisterBody(
          firstName,
          lastName,
          password,
          email,
          school.spsId,
          role,
          position,
          grades != null ? grades : []
        )
      );
      if (error != null) {
        setStatus(error.status);
        console.log(error);
      } else {
        setStatus(200);
        console.log(data);
        if (window._satellite.buildInfo !== undefined){
          console.log("_satellite buildInfo before calling _satellite.track");
          console.log(window._satellite.buildInfo);
          window._satellite.track('registration-modal-my-role-dropdown',  {title: 'My Role Registration Dropdown', role:  role} );
          console.log("_satellite.track event: registration-modal-my-role-dropdown-called");
          if (position){
            window._satellite.track('registration-modal-my-role-position-dropdown',  {title: 'My Role Registration Position Dropdown', position: position });
            console.log("_satellite.track event: registration-modal-my-role-position-dropdown-called");
          } else if (grades) {
            window._satellite.track('registration-modal-my-role-position-dropdown',  {title: 'My Role Registration Position Dropdown', position: grades });
            console.log("_satellite.track event: registration-modal-my-role-position-dropdown-called");
          }
          console.log("_satellite buildInfo after calling _satellite.track");
          console.log(window._satellite.buildInfo);
          history.push({
            pathname: "/register/welcome.html",
            state: { user: data, username: email, password: password },
          });
        }

      }
    }
  };

  const getCountryList = async () => {
    const { data, error } = await getCountries();
    if (error != null) {
      setStatus(error.status);
      console.log(error);
    } else {
      setStatus(200);
      const countryList: any[] = [];
      data.map((dataEntry, key) => {
        let country = { label: dataEntry.value, value: dataEntry.key };
        countryList.push(country);
      });
      setCountries(countryList);
    }
  };

  useEffect(() => {
    const timeout = zipcodeDebouncer(
      setZipcode.bind(
        MySchool,
        handleInput(
          "error",
          validate(zipcode.value, ["zipcodeMinReq"]),
          zipcode
        )
      ),
      5000
    );
    return () => clearTimeout(timeout);
  }, [zipcode.value]);

  useEffect(() => {
    if (
      (schoolType != "" && schoolSelectPhase) ||
      (schoolFindPhase && school != null)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [schoolType, schoolFindPhase, schoolSelectPhase, school]);

  const schoolNotFound = () => {
    setSchoolList([]);
    setSchoolData([]);
    setStatus(200);
    setSchoolSelectPhase(false);
    setSchoolFindPhase(false);
    setSchoolBuildPhase(true);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const getSchools = async () => {
    setIsLoading(true);
    setSchoolList([]);
    const { data, error } = await lookUpSchools(
      zipcode.value,
      schoolType == "ms" ? "O_AP" : "",
      country
    );
    if (error != null) {
      setStatus(error.status);
      setIsLoading(false);
    } else if (data.length == 0) {
      setIsLoading(false);
      setStatus(418);
    } else if (data.length > 0) {
      setIsLoading(false);
      setStatus(200);
      //we add the school data into a format that Select component can read [{label: string, value: string}]
      const oldSchoolList: any[] = [];
      data.map((dataEntry, key) => {
        let newSchool = { label: dataEntry.name, value: dataEntry.spsId };
        oldSchoolList.push(newSchool);
      });
      setSchoolList(oldSchoolList);
      setSchoolData(data);
    }
  };

  useEffect(() => {
    if (zipcode.value.length === 5 || country != "") {
      getSchools();
    }
  }, [zipcode.value, country]);

  return (
    <React.Fragment>
      {isLoading && (
        <div>
          <div className={styles.spinnerModal}>
            <div className={styles.spinnerPosition}>
              <Spinner />
            </div>
          </div>
        </div>
      )}
      <div className={styles.MySchool} data-testid="MySchool">
        <div className={styles.header}>My School</div>

        {schoolSelectPhase && (
          <div className={styles.choiceGroup}>
            <div className={styles.choice}>
              <input
                type="radio"
                id="us"
                name="school_choice"
                value="us"
                checked={schoolType == "us"}
                onChange={(e) => setSchoolType(e.target.value)}
              />
              <a>United States school (Includes U.S. Territories)</a>
            </div>
            <div className={styles.choice}>
              <input
                type="radio"
                id="military"
                name="school_choice"
                value="ms"
                checked={schoolType == "ms"}
                onChange={(e) => setSchoolType(e.target.value)}
              />
              <a>Military base school (APO/FPO)</a>
            </div>
            <div className={styles.choice}>
              <input
                type="radio"
                id="home"
                name="school_choice"
                value="hs"
                checked={schoolType == "hs"}
                onChange={(e) => setSchoolType(e.target.value)}
              />
              <a>Home school</a>
            </div>
            <div className={styles.choice}>
              <input
                type="radio"
                id="non-us"
                name="school_choice"
                value="is"
                checked={schoolType == "is"}
                onChange={(e) => setSchoolType(e.target.value)}
              />
              <a>Non-U.S. school</a>
            </div>
          </div>
        )}
        {schoolFindPhase && (
          <div>
            {(schoolType == "ms" || schoolType == "us") && (
              <div>
                <div>
                  <input
                    className={`${styles.inputGrey} ${
                      zipcode.error === "" || !zipcode.touched
                        ? ""
                        : styles.errorField
                    }`}
                    type="text"
                    pattern="[0-9]*"
                    value={zipcode.value}
                    maxLength={5}
                    placeholder="Enter ZIP code"
                    onSelect={() =>
                      setZipcode(handleInput("touched", true, zipcode))
                    }
                    onChange={(e) => {
                      if (
                        /[0-9]/.test(e.target.value) ||
                        e.target.value == ""
                      ) {
                        setZipcode(
                          handleInput("value", e.target.value, zipcode)
                        );
                      }
                    }}
                  />
                  <div
                    className={`${styles.errorMessage} ${
                      zipcode.error != "" && zipcode.touched
                        ? ""
                        : styles.disable
                    }`}
                  >
                    {zipcode.error}
                  </div>
                  <div
                    className={`${styles.errorMessage} ${
                      status == 418 && zipcode.touched ? "" : styles.disable
                    }`}
                  >
                    Schools could not be found.
                  </div>
                </div>
                {schoolList.length > 0 && zipcode.value.length == 5 && (
                  <Select
                    options={schoolList}
                    placeholder="School"
                    styles={customStyles}
                    onChange={(e) =>
                      schoolData.map((data) => {
                        if (data.spsId == e.value) setSchool(data);
                      })
                    }
                  />
                )}
              </div>
            )}
            {schoolType == "is" && (
              <div>
                <Select
                  options={countries}
                  placeholder="Countries"
                  styles={customStyles}
                  onChange={(e) =>
                    countries.map((data) => {
                      if (data.value == e.value) setCountry(data.value);
                    })
                  }
                />
                {schoolList.length > 0 && (
                  <Select
                    options={schoolList}
                    placeholder="School"
                    styles={customStyles}
                    onChange={(e) =>
                      schoolData.map((data) => {
                        if (data.spsId == e.value) setSchool(data);
                      })
                    }
                  />
                )}
              </div>
            )}
            <div className={`${styles.notFoundLink}`} onClick={schoolNotFound}>
              School not found?
            </div>
          </div>
        )}
        <div
          data-testid="serverErr"
          className={`${status != 200 ? styles.errorMessage : styles.disable}`}
        >
          Something went wrong. Please try again later.
        </div>
        {(schoolFindPhase || schoolSelectPhase) && (
          <div className={styles.buttonContainer}>
            <SubmitButton
              outlined={false}
              name={"Previous"}
              submitFunction={prevHandler}
              id={"register-myschool-prev-button"}
            />
            <SubmitButton
              outlined={false}
              isDisabled={buttonDisabled}
              name={"Next"}
              submitFunction={nextHandler}
              id={"register-myschool-button"}
            />
          </div>
        )}
      </div>
      {schoolBuildPhase && schoolType != "" && (
        <div>
          <SchoolBuilder
            setSchoolPhase={setSchoolSelectPhase}
            setSchoolType={setSchoolType}
            setSchoolBuildPhase={setSchoolBuildPhase}
            selectedSchoolType={schoolType}
            zipCode={zipcode}
            countries={countries}
            selectedCountry={country}
            status={status}
            {...props}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default MySchool;
