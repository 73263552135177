import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useLookupApi = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const useLookupSchoolsApi =  async (zipCode: string, groupType: string, country: string) => {
        return await makeApiRequest(`${apiBase}/lookup/schools?zipcode=${zipCode}&groupType=${groupType}&country=${country}`, {});
    };

    return [useLookupSchoolsApi];
}

export default useLookupApi;