import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';



const useBriteVerifyEmailApi = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('GET');

  const verifyEmail = async (email: string) => {
    return await makeApiRequest(`${apiBase}/api/email/brite?email=${email}`, { "email": email});
  };

  return [verifyEmail];
};

export default useBriteVerifyEmailApi;