import React, { FC, useContext, useEffect, useState } from "react";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import useBroadcast from "../../../hooks/useBroadcast";
import refProps from "../../../interfaces/refProps";
import isBaseline from "../../../utils/is-baseline";
import isDirect from "../../../utils/is-direct";
import styles from "./Welcome.module.scss";
import usePasswordSubmit from "../../../hooks/usePasswordSubmit";
import { isEducator, isEducatorRef } from "../../../utils/is-educator";
import { useLocation } from "react-router-dom";
import eWalletLogo from "../../../styles/images/eWallet_logo.svg";
import welcomeConfig from "../../../interfaces/welcomeConfig";
import { ConfigContext } from "../../../providers/ConfigContext";
import useDumbleData from "../../../hooks/useDumbleData";
import dumbleDataProps from "../../../interfaces/dumbleDataProps";
import useWelcomeEmail from "../../../hooks/useWelcomeEmail";
import refToChannel from "../../../utils/ref-to-channel";

interface WelcomeProps {}

const Welcome = (props: refProps) => {
  const { referrer, chat, reg } = props;
  const broadcast = useBroadcast();
  const [submitPassword] = usePasswordSubmit();
  const [welcomeEmail] = useWelcomeEmail();
  const location = useLocation<any>();
  const welcome = welcomeConfig;
  const { apiBase } = useContext(ConfigContext);
  const [schoolUCN, setSchoolUCN] = useState("");
  const [fairId, setFairId] = useState("");
  const dumbleDataHook = useDumbleData;
  let dumbledata: dumbleDataProps;

  useEffect(() => {
    if (referrer == "ewallet") {
      let pageData = {
        name: "MyScholastic:Registration:Welcome to Book Fairs",
        type: "Registration",
        siteSectionLevel1: "MyScholastic:Registration:Welcome to Book Fairs",
      };
      dumbledata = dumbleDataHook("page", pageData);
    } else if (referrer == "cptk") {
      let pageData = {
        name: "MyScholastic:Registration:Chairperson:Welcome",
        type: "Registration",
        siteSectionLevel1: "MyScholastic:Registration:Chairperson:Welcome",
      };
      dumbledata = dumbleDataHook("page", pageData);
    } else {
      let pageData = {
        name: "MyScholastic:Registration:Welcome",
        type: "Registration",
        siteSectionLevel1: "MyScholastic:Registration:Welcome",
      };
      dumbledata = dumbleDataHook("page", pageData);
    }
  }, []);

  const submitCredentials = async () => {
    //Registrations that skip role selection need to use location.state.password.value
    let wordpass = location.state.password;
    if(location.state.password.value){
      wordpass = location.state.password.value;
    }
    const { data, error } = await submitPassword(
      location.state.username,
      wordpass,
      "",
      referrer
    );
    console.log("ref is " + referrer);

    if (error) {
      console.log("login failed");
      let err = {
        errorType: error.status,
      };
      closeModal();
      return;
    } else {
      console.log("we successfully submitted password");
      let user = {
        loginStatus: true,
        userId: "",
        userType: "",
      };
      user.userType = isEducator(data) ? "Educator" : "Parent";
      user.userId = data?.user?.id;
      notifyWelcome(user.userId, user.userType, data);
    }
  };

  const notifyWelcome = async (userId, userType, succData) => {
    if (isBaseline(referrer) || isDirect(referrer)) {
      const { data, error } = await welcomeEmail(
        userId,
        refToChannel(referrer, userType == "Parent"),
        userType != "Parent"
      );
      if (error) {
        console.log(error);
        successBroadcast(succData, referrer);
      } else {
        successBroadcast(succData, referrer);
      }
    }
  };

  const successBroadcast = (data: any, ref: string) => {
    if (isBaseline(ref)) {
      broadcast(
        {
          status: "SUCCESS",
          data: data,
          callbacks: ["myScholasticOnLoginClose", "myScholasticOnLoginSuccess"],
        },
        ref
      );
      console.log({
        status: "SUCCESS",
        data: data,
        callbacks: ["myScholasticOnLoginClose", "myScholasticOnLoginSuccess"],
      });
    } else {
      broadcast(
        {
          status: "SUCCESS",
          data: data,
        },
        ref
      );
      console.log({ status: "SUCCESS", data: data });
    }
    closeModal();
  };

  const closeModal = () => {
    if (isBaseline(referrer)) {
      broadcast(
        {
          status: "CLOSE",
          data: {},
          callbacks: ["myScholasticOnLoginClose"],
        },
        referrer
      );
      console.log({
        status: "CLOSE",
        data: {},
        callbacks: ["myScholasticOnLoginClose"],
      });
      /* add back this code once we migrate off wrapper
      if(referrer == "ewallet" && fairId != null){
        let pageData = {
          name: 'MyScholastic:Registration:Create eWallet',
          type: 'Registration',
          siteSectionLevel1: 'MyScholastic:Registration:Create eWallet'
        };
        dumbledata = dumbleDataHook('page', pageData);
        window.location.href = `${apiBase}/my-scholastic/profile/create-ewallet.html?sUCN=${schoolUCN}&fairID=${fairId}`;
      }
      */
    } else {
      broadcast(
        {
          status: "CLOSE",
          data: {},
        },
        referrer
      );
      console.log({ status: "CLOSE", data: {} });
    }
  };

  /* when the login-wrapper no longer is needed, we can uncomment this code so that ewallet can redirect to the ewallet page.
  
  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  useEffect(()=> {
    //read for ewallet cookies cookies
    let cookie = getCookie("MYSCHL_EWfairID");
    if( cookie != "" && cookie !=null){
      let fairData = cookie.split("%");
      setFairId(fairData[1]);
      setSchoolUCN(fairData[0]);
    }
  },[])
  */

  return (
    <div className={styles.Welcome} data-testid="Welcome">
      <div className={styles.header}>
        {referrer == "ewallet" ? (
          <div>
            <img src={eWalletLogo} alt="eWallet-logo" />
          </div>
        ) : (
          ""
        )}
        {welcome(referrer)[0]}
      </div>
      <div className={styles.bodyText}>{welcome(referrer)[1]}</div>
      <div className={styles.buttonContainer}>
        <SubmitButton
          outlined={false}
          name={welcome(referrer)[2]}
          submitFunction={submitCredentials}
          id={"register-welcome-button"}
        />
      </div>
    </div>
  );
};

export default Welcome;
