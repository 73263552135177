const  sdmCSR = `Scholastic Magazines+: 1-800-631-1586 (Mon.–Fri., 8 a.m.–7 p.m. EST). \nAll other digital products: 1-800-724-2222 option 6 (Mon.–Fri., 8 a.m.–7 p.m. EST)`;

export const sdmMsg = {
    emailFormatError: "Please enter a properly formatted email address.",
    emailRequiredError: "This field is required.",
    emailApiError: "We don't recognize this email address, please try again.",
    emailInactive: `The account associated with this email address may not be active. Please contact Customer Service:  ${sdmCSR}`,
    passwordApiError: "This password is incorrect. Please try again or click \"Forgot password\" below to reset.",
    passwordPolicyError: "The password doesn't meet our security requirements. Please reset your password by clicking on 'Forgot Password'",
    passwordLocked: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes, or else please contact Customer Service at ${sdmCSR}`,
    forgotPasswordEmailFormatError: "Please enter a properly formatted email address.",
    forgotPasswordEmailApiError: "We don’t recognize this email address, please try again.",
    forgotPasswordEmailInactive: `The account associated with this email address may not be active. Please contact Customer Service: \n ${sdmCSR}`,
    forgotPasswordEmailLocked: `Your account has been locked for security. You will not be able to change your password. Please try again in 30 minutes, or else please contact Customer Service at ${sdmCSR}`,
    forgotYourEmailNameRequiredError: "This field is required.",
    forgotYourEmailNameMinError: "Your name must include at least two letters.",
    forgotYourEmailZipCodeRequiredError: "This field is required.",
    forgotYourEmailZipCodeMinError: "Please enter a valid five-digit U.S ZIP Code",
    resetPasswordConfirmMatchError: "Your password did not match. Try again.",
    resetPasswordTokenError: "The Link has expired, please click on Resend Link to have another email sent to you.",
    unavailableError: `Something went wrong, please try again later or contact: \n ${sdmCSR}.`
}

export default sdmMsg;