import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useWelcomeEmail = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const welcomeEmailResponse = async (schlID: string, requestChannel: string, isEducator: boolean) => {
    return await makeApiRequest(`${apiBase}/api/welcome-email?requestChannel=` + requestChannel + "&schlId=" + schlID + "&isEducator=" + isEducator, {});
  };

  return [welcomeEmailResponse];
}

export default useWelcomeEmail;