import React, { useState, useEffect }  from 'react';
import styles from './forgot-password.module.scss';
import useRequestPasswordResetApi  from '../../hooks/requestPasswordReset';
import { Link , useHistory} from 'react-router-dom';
import validate from '../../utils/validate';
import useDebounce from '../../hooks/useDebounce';
import refProps from '../../interfaces/refProps';
import isBaseline from '../../utils/is-baseline';
import useBroadcast from '../../hooks/useBroadcast';
import clubsMsg from '../../interfaces/clubsMsgConfig';
import sdmMsg from '../../interfaces/sdmMsgConfig';
import baselineMsg from '../../interfaces/baselineMsgConfig';
import { mapPasswordError } from '../../utils/map-password-error';
import close from '../../styles/images/close.svg';
import useDumbleData from '../../hooks/useDumbleData';
import dumbleDataProps from '../../interfaces/dumbleDataProps';


const ForgotPassword = (props: refProps) => {

const skipEmail: boolean = false;
const [isDisabled, setIsDisabled] = useState(true);
const [resetPassword] = useRequestPasswordResetApi();
const [status, setStatus] = useState(0);
const emailDebouncer = useDebounce();
let history =  useHistory();
const [emailField, setEmailField] = useState({
  email: '',
  error: 'Please enter a properly formatted email address.'
});
const [touched, setTouched] = useState(false);
const { referrer, chat } = props;
const broadcast = useBroadcast();
const dumbleDataHook = useDumbleData;
let dumbledata: dumbleDataProps;

useEffect(()=> {
  let pageData = {
    name: 'MyScholastic:Login:ForgotPassword',
    type: 'Login',
    siteSectionLevel1: 'MyScholastic:Login:ForgotPassword'
  };
  dumbledata = dumbleDataHook('page', pageData);
}, []);

const requestResetPassword = async () => {
  setStatus(0);
  //this api call will pass email to MSS to send a password resent link to the email with a token
  const { data, error } = await resetPassword(emailField.email, skipEmail, referrer);
  if (error) {
    if(error.status === 403){
      const feignLockedMessage = 'User ' + emailField.email + ' is locked.';
      setStatus(mapPasswordError(feignLockedMessage, error.message));
    } else {
      setStatus(error.status);
    }
    let err = {
      errorType: error.status    
    };
    dumbledata = dumbleDataHook('error', err);
    return;
  } else {
    //email service needs to be implemented with token
    console.log("data", status, data);
    setStatus(200);
    if(isBaseline(referrer)){
      broadcast({
        status: 'RESET',
        data: {},
        callbacks: ['myScholasticOnLoginClose']
      }, referrer);
      console.log({ status: 'RESET', data: {}, callbacks: ['myScholasticOnLoginClose']});
    } else {
      broadcast({
        status: 'RESET',
        data: {}
      }, referrer);
      console.log({ status: 'RESET', data: {}});
    }
    //redirect to check email page passing email so user can retry
    history.push({
      pathname: '/sign-in/successful-reset.html',
      state: emailField.email
    });
  }
};

const cancel = () => {
  if(isBaseline(referrer)){
    broadcast({
      status: 'CLOSE',
      data: {},
      callbacks: ['myScholasticOnLoginClose']
    }, referrer);
    console.log({ status: 'CLOSE', data: {}, callbacks: ['myScholasticOnLoginClose']});
  } else {
    broadcast({
      status: 'CLOSE',
      data: {}
    }, referrer);
    console.log({ status: 'CLOSE', data: {}});
  }
}

useEffect(() => {
  let errorMsgConfig: any = {};
  if(referrer === 'clubs') {
    errorMsgConfig = clubsMsg;
  } else if (referrer === 'sdm') {
    errorMsgConfig = sdmMsg;
  } else {
    errorMsgConfig.forgotPasswordEmailApiError = baselineMsg('forgotPasswordEmailApiError', referrer);
    errorMsgConfig.forgotPasswordEmailInactive = baselineMsg('forgotPasswordEmailInactive', referrer);
    errorMsgConfig.forgotPasswordEmailLocked = baselineMsg('forgotPasswordEmailLocked', referrer);
    errorMsgConfig.unavailableError = baselineMsg('unavailableError', referrer);
  }
  switch (status) {
    case 0: {
      break;
    }
    case 403: {
      let inactiveError: any;
      if (chat === '1'){
        inactiveError = errorMsgConfig?.forgotPasswordEmailInactiveChatOn;
      } else if (chat === '0'){
        inactiveError = errorMsgConfig?.forgotPasswordEmailInactiveChatOff;
      } else {
        inactiveError = errorMsgConfig?.forgotPasswordEmailInactive;
      }
      setEmailField(handleInput('error', inactiveError, emailField));
      break;
    }
    case 404: {
      setEmailField(handleInput('error', errorMsgConfig?.forgotPasswordEmailApiError, emailField));
      break;
    }
    case 418: {
      let lockedError: any;
      if (chat === '1') {
        lockedError = errorMsgConfig?.forgotPasswordEmailLockedChatOn;
      } else if (chat === '0'){
        lockedError = errorMsgConfig?.forgotPasswordEmailLockedChatOff;
      } else {
        lockedError = errorMsgConfig?.forgotPasswordEmailLocked;
      }
      setEmailField(handleInput('error', lockedError, emailField));
      break;
    }
    case 200: {
      setEmailField(handleInput('error', '', emailField));
      break;
    }
    default: {
      setEmailField(handleInput('error', errorMsgConfig?.unavailableError, emailField));
      break;
    }
  }
}, [status, broadcast, referrer]);

useEffect(
  () => {
  setIsDisabled(emailField.error !== '');
  }, [emailField]
);

useEffect(() => {
  const timeout = emailDebouncer(
    setEmailField.bind(
      ForgotPassword,
      handleInput('error', validate(emailField.email, ['emailFormat']), emailField)
    ),
    300
  )
  return () => clearTimeout(timeout);
}, [emailField.email]);

const handleInput = ((type: string, input: any, field: object) => {
  const newField: any = {...field};
  newField[type] = input;
  return newField;
});

const broadcastChatClubs = () => {
  //checking to see if the word chat is in error msg for broadcast 
  let notAChatLink = emailField?.error.includes('chat') ? true : false;
  if (referrer === 'clubs' && chat === '1' && notAChatLink) {
    //use broadcast on click
    broadcast({
      status: 'CHAT',
      data: {}
    }, referrer);
    console.log({ status: 'CHAT', data: {}});
  }
}

  return (
    <div className={styles.container}>
      <img src={close} alt="close icon" className={`${styles.close} ${isBaseline(referrer) ? styles.disable : ''}`} onClick={cancel}/>
      <div className={styles.header}>Forgot your password?</div>
      <div className={styles.helpText}><p>Enter your email address and we'll send you a link to reset your password.</p></div>
      {/* Email input  */}
      <div className={styles.emailContainer}>
        <input className={`${styles.inputGrey} ${emailField.error === '' || !touched ? '' : styles.errorField}`}
          id="forgot-password-email-input"
          aria-label="enter email"
          type="text"
          autoFocus
          placeholder = "Email address"
          value = {emailField.email}
          onClick={() => setTouched(true)}
          onChange={(e) => {
            if(touched){
              setEmailField(handleInput('email', e.target.value, emailField));
            } else {
              setTouched(true);
              setEmailField(handleInput('email', e.target.value, emailField));
            }
          }}
        />
        {emailField.error !== '' && touched && 
        <div
          // errorMessage only comes from 3 msgConfig in interface, it should NEVER comes from user input
          dangerouslySetInnerHTML={{__html: emailField.error}}
          data-testid="emailErr" 
          className={styles.errorMessage} 
          onClick={broadcastChatClubs}>
        </div>}
      </div>
      {/* submit + API Call */}
      <div className={styles.buttonContainer}>
        <button 
          id="forgot-password-submit-button"
          aria-label="request reset password email"
          disabled={isDisabled} 
          className={`${styles.button} ${styles.purple} ${isDisabled ? styles.disabled: ''}`}
          onClick={requestResetPassword}>
          SUBMIT
        </button>
      </div>
    </div>
  )
}

export default ForgotPassword;