import { useContext, useCallback } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import isBaseline from '../utils/is-baseline';
import usorRedirectConfig from '../interfaces/usorRedirectConfig';

const useRefRedirect = () => {
    const appConfig = useContext(ConfigContext);
    
    return useCallback((ref: string) => {
        if(ref){
            const { origin } = appConfig[ref];
            const { env } = appConfig;

            const redirectURL: string = origin.split(',')[0];

            if(isBaseline(ref)){
                switch(ref){
                    case 'cptk':
                        return redirectURL + `/my-scholastic/register/book-fair-user/book-fair-registration.html?ref=${ref}`;
                    default:
                        return redirectURL + `/my-scholastic/register/basic-information.html?ref=${ref}`;
                }
            } else if(ref === 'sdm'){
                return redirectURL + '/#/createaccount';
            } else if(ref === 'usor'){
                return usorRedirectConfig[env].usor;
            } else {
                return '';
            }
        }
        return '';
    }, [appConfig])
}

export default useRefRedirect;