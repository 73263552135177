export const POSITIONS = [
    {
        value: "ECCD",
        label: "Early Childhood Director"
    },
    {
        value: "LMS",
        label: "Librarian/Media Specialist"
    },
    {
        value: "GC",
        label: "Guidance Counselor"
    },
    {
        value: "ITC",
        label: "Instructional Technology Coordinator"
    },
    {
        value: "PR",
        label: "Principal"
    },
    {
        value: "APR",
        label: "Assistant Principal"
    },
    {
        value: "DA",
        label: "District Administrator"
    },
    {
        value: "AD",
        label: "Assistant Director"
    },
    {
        value: "HS",
        label: "Home Schooler"
    },
    {
        value: "OE",
        label: "Other Educator"
    },
    {
        value: "PCC",
        label: "Parent/Community Coordinator"
    },
    {
        value: "HSC",
        label: "Head Start Coordinator"
    },
    {
        value: "SRP",
        label: "Sponsored Research Program"
    },
    {
        value: "CER",
        label: "Community Engagement & Relations"
    },
    {
        value: "DEID",
        label: "Diversity/Equity/Inclusion Director"
    },
    {
        value: "TIPD",
        label: "Title I Programs Director"
    },
    {
        value: "MCE",
        label: "Multi-Cultural Education"
    },
    {
        value: "BPG",
        label: "Business Partnerships/Grants"
    },
    {
        value: "FPS",
        label: "Federal Programs Specialist"
    },
    {
        value: "BASC",
        label: "Before/After School Coordinator"
    },
    {
        value: "LPCOC",
        label: "Literacy Partners Contact or Coordinator"
    }
    

];
