import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useAddKid = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const addKid = async (body: any) => {
    return await makeApiRequest(`${apiBase}/api/multiple-children`, body);
  };

  return [addKid];
};

export default useAddKid;