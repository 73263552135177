import { useState } from "react";

const useDebounce = () => {
  const [timeoutID, setTimeoutID] = useState(0);
  
  return ((func: Function, timeout: number) => {
    if(timeoutID){
      clearTimeout(timeoutID);
    } 
  
    setTimeoutID(window.setTimeout(() => {
      func();
    }, timeout))

    return timeoutID;
  })
}

export default useDebounce;
