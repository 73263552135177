import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const usePasswordSubmit = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('POST');

    const submitPassword = async (username: string, password: string, reCaptchaToken: string, ref: string) => {
      return await makeApiRequest(`${apiBase}/api/login?reCaptchaToken=${reCaptchaToken}&ref=${ref}`, { "username": username, "password": password});
    };
  
    return [submitPassword];
  };

  export default usePasswordSubmit;