const isEducatorRef = (ref: string) => {
    switch(ref){
        case 'tso':
        case 'tsocheckout':
        case 'tsoprntquote':
        case 'beta':
        case 'tcs':
        case 'bw':
        case 'classmags':
            return true;
        default:
            return false;
    }
}

const isEducator = (data: any) => {
    if(data.user.personas.educator && data.user.personas.educator !== null){
        return true;
    } else {
        return false;
    }
}

export { isEducatorRef, isEducator };