import React from 'react';
import styles from './Spinner.module.scss';

const Spinner: React.FC = () => (
  <div className={styles.Spinner} data-testid="Spinner">
    <div className={styles.loading_green} ></div>
  </div>
);

export default Spinner;
