import axios from 'axios';
import { detect400BadRequest } from '../utils/detect-specific-string-error';

const useMakeApiRequest = (type: string) => {
  interface APIResponse {
    data: any;
    error: any;
  }
  
  const http = axios.create({
    withCredentials: true
  });
  
  const makePostRequest = async (url: string, body: any) => {
    const apiResponse: APIResponse = {
      data: null,
      error: null
    };

    try {
      const { data } = await http.post(url, body);
      apiResponse.data = data;
    } catch (err: any) {
      console.log("Error catched during api call,", err);
      // this if-block handles the edge case when response is empty or doesn't exist
      if(!err || !err.response){
        console.log("Error contained empty or no response,", err.response);
        apiResponse.error = { message: "empty or no response", status: 500 }
      // this if-block handles expected error response, where response has data and status as an object (json)
      } else if(err.response.data && err.response.status && typeof(err.response.data) === 'object'){
        console.log("Error contained expected error response type,", err.response);
        apiResponse.error = err.response?.data;
        if (!apiResponse.error.status) {
          apiResponse.error.status = err.response.status;
        }
      // this else-block handles every unexpected error response, such as HTML string literal
      } else {
        // this if-block handles unexpected, tomcat 400 bad request error response.
        // setting body to apiResponse.error so retry attempt can be done in verify-email step
        // otherwise returning 500 as it conflicts with existing 400 status code (invalid reCaptchaToken) in password screen
        if(typeof(err.response.data) === 'string' && detect400BadRequest(err.response.data)){
          console.log("Error contained 400 - Bad Request unexpected error,", err.response);
          apiResponse.error = { message: err.response.data, status: 500 }
        // this else-block catches rest of the unexpected error responses.
        } else {
          console.log("Error contained unexpected error response type,", err.response);
          apiResponse.error = { message: "unexpected error response", status: 500 }
        }
      }
    }
    return apiResponse;
  };

  const makePutRequest = async (url: string, body: any) => {
    const apiResponse: APIResponse = {
        data: null,
        error: null
      };
    
    try {
      const { data } = await http.put(url, body);
      console.log(data);
      apiResponse.data = data;
    } catch (err: any) {
      console.log("Error catched during api call,", err);
      // this if-block handles the edge case when response is empty or doesn't exist
      if(!err || !err.response){
        console.log("Error contained empty or no response,", err.response);
        apiResponse.error = { message: "empty or no response", status: 500 }
      // this if-block handles expected error response, where response has data and status as an object (json)
      } else if(err.response.data && err.response.status && typeof(err.response.data) === 'object'){
        console.log("Error contained expected error response type,", err.response);
        apiResponse.error = err.response?.data;
        if (!apiResponse.error.status) {
          apiResponse.error.status = err.response.status;
        }
      // this else-block handles every unexpected error response, such as HTML string literal
      } else {
        // this if-block handles unexpected, tomcat 400 bad request error response.
        // setting body to apiResponse.error so retry attempt can be done in verify-email step
        // otherwise returning 500 as it conflicts with existing 400 status code (invalid reCaptchaToken) in password screen
        if(typeof(err.response.data) === 'string' && detect400BadRequest(err.response.data)){
          console.log("Error contained 400 - Bad Request unexpected error,", err.response);
          apiResponse.error = { message: err.response.data, status: 500 }
        // this else-block catches rest of the unexpected error responses.
        } else {
          console.log("Error contained unexpected error response type,", err.response);
          apiResponse.error = { message: "unexpected error response", status: 500 }
        }
      }
    }
    return apiResponse;
  };



  const makeGetRequest = async (url: string, body: any) => {
    const apiResponse: APIResponse = {
      data: null,
      error: null
    };

    try {
      const { data } = await http.get(url, body);
      apiResponse.data = data;
    } catch (err: any) {
      console.log("Error catched during api call,", err);
      // this if-block handles the edge case when response is empty or doesn't exist
      if(!err || !err.response){
        console.log("Error contained empty or no response,", err.response);
        apiResponse.error = { message: "empty or no response", status: 500 }
      // this if-block handles expected error response, where response has data and status as an object (json)
      } else if(err.response.data && err.response.status && typeof(err.response.data) === 'object'){
        console.log("Error contained expected error response type,", err.response);
        apiResponse.error = err.response?.data;
        if (!apiResponse.error.status) {
          apiResponse.error.status = err.response.status;
        }
      // this else-block handles every unexpected error response, such as HTML string literal
      } else {
        // this if-block handles unexpected, tomcat 400 bad request error response.
        // setting body to apiResponse.error so retry attempt can be done in verify-email step
        // otherwise returning 500 as it conflicts with existing 400 status code (invalid reCaptchaToken) in password screen
        if(typeof(err.response.data) === 'string' && detect400BadRequest(err.response.data)){
          console.log("Error contained 400 - Bad Request unexpected error,", err.response);
          apiResponse.error = { message: err.response.data, status: 500 }
        // this else-block catches rest of the unexpected error responses.
        } else {
          console.log("Error contained unexpected error response type,", err.response);
          apiResponse.error = { message: "unexpected error response", status: 500 }
        }
      }
    }
    return apiResponse;
  };

  switch(type){
    case 'POST':
      return makePostRequest;
    case 'PUT':
      return makePutRequest;
    case 'GET':
      return makeGetRequest;
    default:
      return makeGetRequest;
  }
}

export default useMakeApiRequest;