import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConfigContext, ConfigProvider } from './providers/ConfigContext';
import SignIn from './pages/Login/SignIn/SignIn';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ResetPassword from './pages/Login/ResetPassword/ResetPassword';
import ForgotPassword from './components/TroubleSigningIn/ForgotPassword';
import CheckEmail from './components/CheckEmail/check-email';
import SubmitPassword from './pages/Login/SubmitPassword/SubmitPassword';
import { parseQueryParams } from './utils/query-params';
import MyInfo from './pages/Register/MyInfo/MyInfo';
import RegisterPassword from './pages/Register/RegisterPassword/RegisterPassword';
import MyRole from './pages/Register/MyRole/MyRole';
import MySchool from './pages/Register/MySchool/MySchool';
import MyKids from './pages/Register/MyKids/MyKids';
import Welcome from './pages/Register/Welcome/Welcome';
import RouterGuard from './utils/router-guard';
import SchoolBuilder from './components/SchoolBuilder/SchoolBuilder';
import ThankYou from './pages/Register/ThankYou/ThankYou';
import RegisterBFC from './pages/Register/RegisterBFC/RegisterBFC';

function App() {
  const { ref, chat, reg, ignore } = parseQueryParams(window.location);
  const appConfig = useContext(ConfigContext);
  const env = process.env.REACT_APP_ENV;

  let referrer: any = '';
  if(ref !== null){
    referrer = Array.isArray(ref) ? ref[0] : ref;
  }
  // TODO - remove this debug log when ref & broadcast is done with all teams
  console.log('Referrer is set to: ' + referrer);
  console.log('chat param is set to:', chat);
  console.log('reg param is set to:', reg);
  console.log('ignore param is set to:', ignore);
  console.log('env param is set to:', env);

  return (
    <ConfigProvider>
      <Header/>
      <Switch>
        <Route path='/sign-in.html' render={() => <SignIn referrer={referrer} chat={chat} reg={reg} ignore={ignore} />}/>
        <Route path='/sign-in/reset-password.html' render={() => <ResetPassword referrer={referrer} chat={chat} reg={reg} />}/>
        <Route path='/sign-in/submit-password.html' render={() => <SubmitPassword referrer={referrer} chat={chat} reg={reg} ignore={ignore}/>}/>
        <Route path='/sign-in/forgot-password.html' render={() => <ForgotPassword referrer={referrer} chat={chat} reg={reg}/>}></Route> //forgot password by email
        <Route path='/sign-in/successful-reset.html' render={() => <CheckEmail referrer={referrer} chat={chat} reg={reg}/>}></Route>

        <Route path='/register/my-info.html' render={() => <MyInfo referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/register-password.html' render={() => <RegisterPassword referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/my-role.html' render={() => <MyRole referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/my-school.html' render={() => <MySchool referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/my-school-info.html' render={() => <SchoolBuilder referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/my-kids.html' render={() => <MyKids referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/welcome.html' render={() => <Welcome referrer={referrer} chat={chat}  reg={reg}/>}/>
        <Route path='/register/thankyou.html' render={() => <ThankYou referrer={referrer} chat={chat}  reg={reg}/>}/>

        {/* 
        <RouterGuard path='/register/my-info.html' component={MyInfo} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/register-password.html' component={RegisterPassword} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/my-role.html' component={MyRole} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/my-school.html' component={MySchool} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/my-school-info.html' component={SchoolBuilder} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/my-kids.html' component={MyKids} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/welcome.html' component={Welcome} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        <RouterGuard path='/register/thankyou.html' component={ThankYou} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/>
        */}
        <RouterGuard path='/register/bfc.html' component={RegisterBFC} referrer={referrer} chat={chat}  reg={reg} auth={env != 'prod'}/> 
        <Route path=''><Redirect to='/sign-in.html' /></Route>
      </Switch>
      <Footer/>
    </ConfigProvider>
  );
}

export default App;
