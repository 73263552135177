import React, { FC, useDebugValue, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import Spinner from "../Spinner/Spinner";
import SubmitButton from "../SubmitButton/SubmitButton";
import useBriteVerifyEmailApi from "../../hooks/briteVerifyEmail";
import useDebounce from "../../hooks/useDebounce";
import useVerifyEmail from "../../hooks/useVerifyEmail";
import refProps from "../../interfaces/refProps";
import registerProps from "../../interfaces/registerProps";
import validate from "../../utils/validate";
import styles from "./SchoolBuilder.module.scss";
import { customStyles } from "../../utils/select-custom-styles";
import { STATES } from "../../constants/states";
import useRegisterSchool from "../../hooks/useRegisterSchool";
import { stat } from "fs";

const SchoolBuilder = (schoolEventHandlers, props: refProps) => {
  const location = useLocation<any>();
  let history = useHistory();
  const { referrer, chat, reg } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [status, setStatus] = useState(200);
  const schoolNameDebouncer = useDebounce();
  const addressLine1Debouncer = useDebounce();
  const addressLine2Debouncer = useDebounce();
  const schoolTypeDebouncer = useDebounce();
  const schoolTypeISDebouncer = useDebounce();
  const cityDebouncer = useDebounce();
  const stateDebouncer = useDebounce();
  const zipDebouncer = useDebounce();
  const phoneDebouncer = useDebounce();
  const postOfficeDebouncer = useDebounce();
  const [registerSchool] = useRegisterSchool();
  const POSTOFFICE = [{ label: "APO/FPO", value: "APO/FPO" }];
  const MS_STATES = [
    { label: "Armed Forces America", value: "AA" },
    { label: "Armed Forces", value: "AE" },
    { label: "Armed Forces Pacific", value: "AP" },
  ];
  const SCHOOL_TYPES = [
    { label: "Home school", value: "hos" },
    { label: "International School", value: "isi" },
    { label: "US School Abroad", value: "usi" },
  ];
  const US_SCHOOL_TYPES = [
    { label: "College", value: "c" },
    { label: "District", value: "d" },
    { label: "Early Childhood", value: "ec" },
    { label: "Library", value: "l" },
    { label: "Other", value: "o" },
  ];
  const [schoolName, setschoolName] = useState({
    value:
      location.state?.schoolName?.value === undefined
        ? ""
        : location.state.schoolName.value,
    error: "This field is required.",
    touched: location.state?.schoolName?.value === undefined ? false : true,
  });
  const [addressLine1, setAddressLine1] = useState({
    value:
      location.state?.addressLine1?.value === undefined
        ? ""
        : location.state.addressLine1.value,
    error: "This field is required.",
    touched: location.state?.addressLine1?.value === undefined ? false : true,
  });
  const [addressLine2, setAddressLine2] = useState({
    value:
      location.state?.addressLine2?.value === undefined
        ? ""
        : location.state.addressLine2.value,
    error: "",
    touched: location.state?.addressLine2?.value === undefined ? false : true,
  });
  const [city, setCity] = useState({
    value:
      location.state?.city?.value === undefined
        ? ""
        : location.state.city.value,
    error: "This field is required.",
    touched: location.state?.city?.value === undefined ? false : true,
  });
  const [state, setState] = useState({
    value:
      location.state?.state?.value === undefined
        ? ""
        : location.state.state.value,
    error: "This field is required.",
    touched: location.state?.state?.value === undefined ? false : true,
  });
  const [zip, setZip] = useState({
    value:
      location.state?.zip?.value === undefined
        ? schoolEventHandlers.zipCode.value ?? ""
        : location.state.zip.value,
    error: "This field is required.",
    touched: location.state?.zip?.value === undefined ? false : true,
  });
  const [phone, setPhone] = useState({
    value:
      location.state?.phone?.value === undefined
        ? ""
        : location.state.phone.value,
    error: "This field is required.",
    touched: location.state?.phone?.value === undefined ? false : true,
  });
  const [postOffice, setPostOffice] = useState({
    value:
      location.state?.postOffice?.value === undefined
        ? ""
        : location.state.postOffice.value,
    error: "This field is required.",
    touched: location.state?.postOffice?.value === undefined ? false : true,
  });
  const [country, setCountry] = useState({
    value:
      location.state?.country?.value === undefined
        ? schoolEventHandlers.selectedCountry ?? ""
        : location.state.country.value,
    error: "",
    touched: location.state?.country?.value === undefined ? false : true,
  });
  const [schoolType, setschoolType] = useState({
    value:
      location.state?.schoolType?.value === undefined
        ? ""
        : location.state.schoolType.value,
    error: "This field is required.",
    touched: location.state?.schoolType?.value === undefined ? false : true,
  });
  const [schoolTypeIS, setSchoolTypeIS] = useState({
    value:
      location.state?.schoolTypeIS?.value === undefined
        ? ""
        : location.state.schoolTypeIS.value,
    error: "This field is required.",
    touched: location.state?.schoolTypeIS?.value === undefined ? false : true,
  });
  const handleInput = (type: string, input: any, field: object) => {
    const newField: any = { ...field };
    newField[type] = input;
    return newField;
  };
  useEffect(() => {
    const timeout = schoolNameDebouncer(
      setschoolName.bind(
        SchoolBuilder,
        handleInput(
          "error",
          validate(schoolName.value, ["required", "schoolMinReq"]),
          schoolName
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [schoolName.value]);

  useEffect(() => {
    const timeout = addressLine1Debouncer(
      setAddressLine1.bind(
        SchoolBuilder,
        handleInput(
          "error",
          validate(addressLine1.value, ["required", "addressMinReq"]),
          addressLine1
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [addressLine1.value]);

  useEffect(() => {
    const timeout = addressLine2Debouncer(
      setAddressLine2.bind(
        SchoolBuilder,
        handleInput("error", validate(addressLine2.value, []), addressLine2)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [addressLine2.value]);

  useEffect(() => {
    const timeout = cityDebouncer(
      setCity.bind(
        SchoolBuilder,
        handleInput(
          "error",
          validate(city.value, ["required", "cityMinReq"]),
          city
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [city.value]);

  useEffect(() => {
    const timeout = stateDebouncer(
      setState.bind(
        SchoolBuilder,
        handleInput("error", validate(state.value, ["required"]), state)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [state.value]);

  useEffect(() => {
    const timeout = schoolTypeDebouncer(
      setschoolType.bind(
        SchoolBuilder,
        handleInput("error", validate(schoolType.value, ["required"]), schoolType)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [schoolType.value]);

  useEffect(() => {
    const timeout = schoolTypeISDebouncer(
      setSchoolTypeIS.bind(
        SchoolBuilder,
        handleInput("error", validate(schoolTypeIS.value, ["required"]), schoolTypeIS)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [schoolTypeIS.value]);

  useEffect(() => {
    const timeout =
      schoolEventHandlers.selectedSchoolType == "is"
        ? zipDebouncer(
            setZip.bind(
              SchoolBuilder,
              handleInput("error", validate(zip.value, ["required"]), zip)
            ),
            300
          )
        : zipDebouncer(
            setZip.bind(
              SchoolBuilder,
              handleInput(
                "error",
                validate(zip.value, ["required", "zipcodeMinReq"]),
                zip
              )
            ),
            300
          );
    return () => clearTimeout(timeout);
  }, [zip.value]);

  useEffect(() => {
    const timeout = phoneDebouncer(
      setPhone.bind(
        SchoolBuilder,
        handleInput(
          "error",
          validate(phone.value, ["required", "phoneMinReq"]),
          phone
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [phone.value]);

  useEffect(() => {
    const timeout = postOfficeDebouncer(
      setPostOffice.bind(
        SchoolBuilder,
        handleInput(
          "error",
          validate(postOffice.value, ["required", "postOfficeMinReq"]),
          postOffice
        )
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [postOffice.value]);

  const prev = () => {
    schoolEventHandlers.setSchoolPhase(true);
    schoolEventHandlers.setSchoolBuildPhase(false);
    schoolEventHandlers.setSchoolType("");
  };
  useEffect(() => {
    if (dataValidation()) {
      if (
        (schoolEventHandlers.selectedSchoolType == "hs" ||
          schoolEventHandlers.selectedSchoolType == "us") &&
        city.error == ""
      ) {
        setIsDisabled(false);
      } else if (
        schoolEventHandlers.selectedSchoolType == "ms" &&
        postOffice.error == ""
      ) {
        setIsDisabled(false);
      } else if (
        schoolEventHandlers.selectedSchoolType == "is" &&
        country.error == "" &&
        schoolTypeIS.error == ""
      ) {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [
    schoolName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    phone,
    postOffice,
    schoolType,
    schoolTypeIS,
    country
  ]);

  const dataValidation = () => {
    if (
      schoolName.error == "" &&
      addressLine1.error == "" &&
      state.error == "" &&
      zip.error == "" &&
      phone.error == "" &&
      status == 200
    ) {
        return true;
    }
    else return false;
  };

  const touchAll = () =>{
    handleInput("touched", true, schoolName);
    handleInput("touched", true, addressLine1);
    handleInput("touched", true, addressLine2);
    handleInput("touched", true, schoolType);
    if (schoolEventHandlers.selectedSchoolType == "is") handleInput("touched", true, schoolTypeIS);
    handleInput("touched", true, phone);
    handleInput("touched", true, zip);
    handleInput("touched", true, state);
    if (schoolEventHandlers.selectedSchoolType == "ms") handleInput("touched", true, postOffice);
  }

  const createAccount = () => {
    touchAll();
    let payload = {
      name: schoolName.value,
      address1: addressLine1.value,
      state: state.value,
      zipcode: zip.value,
      phone: phone.value,
    };
    if (addressLine2.value != "") {
      payload["address2"] = addressLine2.value;
    }
    if (schoolEventHandlers.selectedSchoolType == "hs") {
      //Home School
      payload["city"] = city.value;
      payload["country"] = "US";
      payload["locationType"] = "HS";
      payload["reportingSchoolType"] = "HS";
    } else if (schoolEventHandlers.selectedSchoolType == "us") {
      //US School
      payload["country"] = "US";
      payload["city"] = city.value;
      payload["locationType"] = "US";
      payload["reportingSchoolType"] = schoolType.value;
    } else if (schoolEventHandlers.selectedSchoolType == "ms") {
      //Military School
      payload["postOffice"] = postOffice.value;
      payload["city"] = "APO/FPO";
      payload["locationType"] = "MS";
      payload["reportingSchoolType"] = "AP";
    } else if (schoolEventHandlers.selectedSchoolType == "is") {
      //International School
      payload["city"] = city.value;
      payload["locationType"] = "NUS";
      payload["country"] = country.value;
      payload["reportingSchoolType"] = schoolTypeIS.value;
    }
    submitSchool(payload);
  };

  const submitSchool = async (payload: any) => {
    const { data, error } = await registerSchool(payload);
    if (error) {
      setStatus(error.status);
      console.log(error);
    } else if (data != null) {
      setStatus(200);
      history.push({
        pathname: "/register/thankyou.html",
        state: {
          role: location.state.role,
          grades: location.state.grades,
          firstName: location.state.firstName,
          lastName: location.state.lastName,
          email: location.state.email,
          password: location.state.password,
          position: location.state.position,
          school: data,
        },
      });
    }
  };

  return (
    <div className={styles.SchoolBuilder} data-testid="SchoolBuilder">
      {isLoading && (
        <div>
          <div className={styles.spinnerModal}>
            <div className={styles.spinnerPosition}>
              <Spinner />
            </div>
          </div>
        </div>
      )}

      <div className={styles.myInfoContainer}>
        <div className={styles.warning}>
          Please note: Scholastic must verify your school's information before
          your account can be confirmed.
        </div>
        <br />
        {schoolEventHandlers.selectedSchoolType == "hs" && (
          <div>
            <div className={styles.requiredContainer}>
              <span className={styles.asterik}>*</span>
              <span className={styles.label}> Required</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <input
                      className={`${styles.inputGrey} ${
                        schoolName.error == "" || !schoolName.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="school-name"
                      aria-label="enter school name"
                      name="schoolName"
                      autoFocus
                      value={schoolName.value}
                      placeholder="School Name *"
                      onSelect={() =>
                        setschoolName(handleInput("touched", true, schoolName))
                      }
                      onChange={(e) =>
                        setschoolName(
                          handleInput("value", e.target.value, schoolName)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="schoolNameErr"
                      className={`${
                        schoolName.error != "" && schoolName.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolName.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine1.error == "" || !addressLine1.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-1"
                      aria-label="enter address line 1"
                      name="addressLine1"
                      autoFocus
                      value={addressLine1.value}
                      placeholder="Address Line 1 *"
                      onSelect={() =>
                        setAddressLine1(
                          handleInput("touched", true, addressLine1)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine1(
                          handleInput("value", e.target.value, addressLine1)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine1Err"
                      className={`${
                        addressLine1.error != "" && addressLine1.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine1.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine2.error == "" || !addressLine2.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-2"
                      aria-label="enter address line 2"
                      name="addressLine2"
                      autoFocus
                      value={addressLine2.value}
                      placeholder="Address Line 2"
                      onSelect={() =>
                        setAddressLine2(
                          handleInput("touched", true, addressLine2)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine2(
                          handleInput("value", e.target.value, addressLine2)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine2Err"
                      className={`${
                        addressLine2.error != "" && addressLine2.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine2.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        city.error == "" || !city.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="city"
                      aria-label="enter city"
                      name="city"
                      autoFocus
                      value={city.value}
                      placeholder="City *"
                      onSelect={() =>
                        setCity(handleInput("touched", true, city))
                      }
                      onChange={(e) =>
                        setCity(handleInput("value", e.target.value, city))
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="cityErr"
                      className={`${
                        city.error != "" && city.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {city.error}
                    </div>
                  </td>
                  <td>
                    <Select
                      aria-label="select state"
                      options={STATES}
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="State *"
                      onFocus={() =>
                        setState(handleInput("touched", true, state))
                      }
                      onChange={(e: any) =>
                        setState(handleInput("value", e.value, state))
                      }
                    />
                    <div
                      data-testid="stateErr"
                      className={`${
                        state.error != "" && state.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {state.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        zip.error == "" || !zip.touched ? "" : styles.errorField
                      }`}
                      id="zip"
                      aria-label="enter zip"
                      name="zip"
                      autoFocus
                      value={zip.value}
                      placeholder="Zip *"
                      onSelect={() => setZip(handleInput("touched", true, zip))}
                      onChange={(e) =>
                        setZip(handleInput("value", e.target.value, zip))
                      }
                      maxLength={5}
                      type={"text"}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="zipErr"
                      className={`${
                        zip.error != "" && zip.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {zip.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        phone.error == "" || !phone.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="phone"
                      aria-label="enter phone"
                      name="phone"
                      type={"tel"}
                      autoFocus
                      value={phone.value}
                      placeholder="Phone *"
                      onSelect={() =>
                        setPhone(handleInput("touched", true, phone))
                      }
                      onChange={(e) =>
                        setPhone(handleInput("value", e.target.value, phone))
                      }
                      maxLength={10}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="phoneErr"
                      className={`${
                        phone.error != "" && phone.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {phone.error}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {schoolEventHandlers.selectedSchoolType == "ms" && (
          <div>
            <div className={styles.requiredContainer}>
              <span className={styles.asterik}>*</span>
              <span className={styles.label}> Required</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <input
                      className={`${styles.inputGrey} ${
                        schoolName.error == "" || !schoolName.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="school-name"
                      aria-label="enter school name"
                      name="schoolName"
                      autoFocus
                      value={schoolName.value}
                      placeholder="School Name *"
                      onSelect={() =>
                        setschoolName(handleInput("touched", true, schoolName))
                      }
                      onChange={(e) =>
                        setschoolName(
                          handleInput("value", e.target.value, schoolName)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="schoolNameErr"
                      className={`${
                        schoolName.error != "" && schoolName.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolName.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine1.error == "" || !addressLine1.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-1"
                      aria-label="enter address line 1"
                      name="addressLine1"
                      autoFocus
                      value={addressLine1.value}
                      placeholder="Address Line 1 *"
                      onSelect={() =>
                        setAddressLine1(
                          handleInput("touched", true, addressLine1)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine1(
                          handleInput("value", e.target.value, addressLine1)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine1Err"
                      className={`${
                        addressLine1.error != "" && addressLine1.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine1.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine2.error == "" || !addressLine2.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-2"
                      aria-label="enter address line 2"
                      name="addressLine2"
                      autoFocus
                      value={addressLine2.value}
                      placeholder="Address Line 2"
                      onSelect={() =>
                        setAddressLine2(
                          handleInput("touched", true, addressLine2)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine2(
                          handleInput("value", e.target.value, addressLine2)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine2Err"
                      className={`${
                        addressLine2.error != "" && addressLine2.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine2.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      aria-label="select state"
                      options={MS_STATES}
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="State *"
                      onFocus={() =>
                        setState(handleInput("touched", true, state))
                      }
                      onChange={(e: any) =>
                        setState(handleInput("value", e.value, state))
                      }
                    />
                    <div
                      data-testid="stateErr"
                      className={`${
                        state.error != "" && state.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {state.error}
                    </div>
                  </td>
                  <td>
                    <Select
                      aria-label="select post office"
                      options={POSTOFFICE}
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="Post Office *"
                      onFocus={() =>
                        setPostOffice(handleInput("touched", true, postOffice))
                      }
                      onChange={(e: any) =>
                        setPostOffice(handleInput("value", e.value, postOffice))
                      }
                    />
                    <div
                      data-testid="postOfficeErr"
                      className={`${
                        postOffice.error != "" && postOffice.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {postOffice.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        zip.error == "" || !zip.touched ? "" : styles.errorField
                      }`}
                      id="zip"
                      aria-label="enter zip"
                      name="zip"
                      autoFocus
                      value={zip.value}
                      placeholder="Zip *"
                      onSelect={() => setZip(handleInput("touched", true, zip))}
                      onChange={(e) =>
                        setZip(handleInput("value", e.target.value, zip))
                      }
                      maxLength={5}
                      type={"text"}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="zipErr"
                      className={`${
                        zip.error != "" && zip.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {zip.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        phone.error == "" || !phone.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="phone"
                      aria-label="enter phone"
                      name="phone"
                      type={"tel"}
                      autoFocus
                      value={phone.value}
                      placeholder="Phone *"
                      onSelect={() =>
                        setPhone(handleInput("touched", true, phone))
                      }
                      onChange={(e) =>
                        setPhone(handleInput("value", e.target.value, phone))
                      }
                      maxLength={10}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="phoneErr"
                      className={`${
                        phone.error != "" && phone.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {phone.error}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {schoolEventHandlers.selectedSchoolType == "is" && (
          <div>
            <div className={styles.requiredContainer}>
              <span className={styles.asterik}>*</span>
              <span className={styles.label}> Required</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <input
                      className={`${styles.inputGrey} ${
                        schoolName.error == "" || !schoolName.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="school-name"
                      aria-label="enter school name"
                      name="schoolName"
                      autoFocus
                      value={schoolName.value}
                      placeholder="School Name *"
                      onSelect={() =>
                        setschoolName(handleInput("touched", true, schoolName))
                      }
                      onChange={(e) =>
                        setschoolName(
                          handleInput("value", e.target.value, schoolName)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="schoolNameErr"
                      className={`${
                        schoolName.error != "" && schoolName.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolName.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine1.error == "" || !addressLine1.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-1"
                      aria-label="enter address line 1"
                      name="addressLine1"
                      autoFocus
                      value={addressLine1.value}
                      placeholder="Address Line 1 *"
                      onSelect={() =>
                        setAddressLine1(
                          handleInput("touched", true, addressLine1)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine1(
                          handleInput("value", e.target.value, addressLine1)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine1Err"
                      className={`${
                        addressLine1.error != "" && addressLine1.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine1.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine2.error == "" || !addressLine2.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-2"
                      aria-label="enter address line 2"
                      name="addressLine2"
                      autoFocus
                      value={addressLine2.value}
                      placeholder="Address Line 2"
                      onSelect={() =>
                        setAddressLine2(
                          handleInput("touched", true, addressLine2)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine2(
                          handleInput("value", e.target.value, addressLine2)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine2Err"
                      className={`${
                        addressLine2.error != "" && addressLine2.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine2.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        city.error == "" || !city.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="city"
                      aria-label="enter city"
                      name="city"
                      autoFocus
                      value={city.value}
                      placeholder="City *"
                      onSelect={() =>
                        setCity(handleInput("touched", true, city))
                      }
                      onChange={(e) =>
                        setCity(handleInput("value", e.target.value, city))
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="cityErr"
                      className={`${
                        city.error != "" && city.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {city.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        state.error == "" || !state.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="state"
                      aria-label="enter state"
                      name="state"
                      autoFocus
                      value={state.value}
                      placeholder="State/Region *"
                      onSelect={() =>
                        setState(handleInput("touched", true, state))
                      }
                      onChange={(e) =>
                        setState(handleInput("value", e.target.value, state))
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="stateErr"
                      className={`${
                        state.error != "" && state.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {state.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        zip.error == "" || !zip.touched ? "" : styles.errorField
                      }`}
                      id="zip"
                      aria-label="enter zip"
                      name="zip"
                      autoFocus
                      value={zip.value}
                      placeholder="Zip *"
                      onSelect={() => setZip(handleInput("touched", true, zip))}
                      onChange={(e) =>
                        setZip(handleInput("value", e.target.value, zip))
                      }
                      maxLength={10}
                      type={"text"}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="zipErr"
                      className={`${
                        zip.error != "" && zip.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {zip.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        phone.error == "" || !phone.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="phone"
                      aria-label="enter phone"
                      name="phone"
                      type={"tel"}
                      value={phone.value}
                      placeholder="Phone *"
                      onSelect={() =>
                        setPhone(handleInput("touched", true, phone))
                      }
                      onChange={(e) =>
                        setPhone(handleInput("value", e.target.value, phone))
                      }
                      maxLength={10}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="phoneErr"
                      className={`${
                        phone.error != "" && phone.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {phone.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      options={schoolEventHandlers.countries}
                      placeholder="Countries"
                      styles={customStyles}
                      onChange={(e: any) =>
                        schoolEventHandlers.countries.map((data) => {
                          if (data.value == e.value) setCountry(handleInput("value", e.value, country));
                        })
                      }
                      defaultValue={schoolEventHandlers.countries.map(
                        (data) => {
                          if (data.value == schoolEventHandlers.selectedCountry)
                            return data;
                        }
                      )}
                    />
                    <div
                      data-testid="countryErr"
                      className={`${
                        country.error != "" && country.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {country.error}
                    </div>
                  </td>
                  <td>
                    <Select
                      aria-label="select schoolType"
                      options={SCHOOL_TYPES}
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="School Type *"
                      onFocus={() =>
                        setSchoolTypeIS(handleInput("touched", true, schoolTypeIS))
                      }
                      onChange={(e: any) =>
                        setSchoolTypeIS(
                          handleInput("value", e.value, schoolTypeIS)
                        )
                      }
                    />
                    <div
                      data-testid="schoolTypeISErr"
                      className={`${
                        schoolTypeIS.error != "" && schoolTypeIS.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolTypeIS.error}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {schoolEventHandlers.selectedSchoolType == "us" && (
          <div>
            <div className={styles.requiredContainer}>
              <span className={styles.asterik}>*</span>
              <span className={styles.label}> Required</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        schoolName.error == "" || !schoolName.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="school-name"
                      aria-label="enter school name"
                      name="schoolName"
                      autoFocus
                      value={schoolName.value}
                      placeholder="School Name *"
                      onSelect={() =>
                        setschoolName(handleInput("touched", true, schoolName))
                      }
                      onChange={(e) =>
                        setschoolName(
                          handleInput("value", e.target.value, schoolName)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="schoolNameErr"
                      className={`${
                        schoolName.error != "" && schoolName.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolName.error}
                    </div>
                  </td>
                  <td>
                    <Select
                      aria-label="select schoolType"
                      options={US_SCHOOL_TYPES}
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="School Type *"
                      onFocus={() =>
                        setschoolType(handleInput("touched", true, schoolType))
                      }
                      onChange={(e: any) =>
                        setschoolType(handleInput("value", e.value, schoolType))
                      }
                    />
                    <div
                      data-testid="schoolTypeErr"
                      className={`${
                        schoolType.error != "" && schoolType.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {schoolType.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine1.error == "" || !addressLine1.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-1"
                      aria-label="enter address line 1"
                      name="addressLine1"
                      autoFocus
                      value={addressLine1.value}
                      placeholder="Address Line 1 *"
                      onSelect={() =>
                        setAddressLine1(
                          handleInput("touched", true, addressLine1)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine1(
                          handleInput("value", e.target.value, addressLine1)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine1Err"
                      className={`${
                        addressLine1.error != "" && addressLine1.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine1.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        addressLine2.error == "" || !addressLine2.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="address-line-2"
                      aria-label="enter address line 2"
                      name="addressLine2"
                      autoFocus
                      value={addressLine2.value}
                      placeholder="Address Line 2"
                      onSelect={() =>
                        setAddressLine2(
                          handleInput("touched", true, addressLine2)
                        )
                      }
                      onChange={(e) =>
                        setAddressLine2(
                          handleInput("value", e.target.value, addressLine2)
                        )
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="addressLine2Err"
                      className={`${
                        addressLine2.error != "" && addressLine2.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {addressLine2.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        city.error == "" || !city.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="city"
                      aria-label="enter city"
                      name="city"
                      autoFocus
                      value={city.value}
                      placeholder="City *"
                      onSelect={() =>
                        setCity(handleInput("touched", true, city))
                      }
                      onChange={(e) =>
                        setCity(handleInput("value", e.target.value, city))
                      }
                      maxLength={30}
                    />
                    <div
                      data-testid="cityErr"
                      className={`${
                        city.error != "" && city.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {city.error}
                    </div>
                  </td>
                  <td>
                    <Select
                      aria-label="select state"
                      options={STATES}
                      onFocus={() =>
                        setState(handleInput("touched", true, state))
                      }
                      className={styles.selectionInput}
                      styles={customStyles}
                      placeholder="State *"
                      onChange={(e: any) =>
                        setState(handleInput("value", e.value, state))
                      }
                    />
                    <div
                      data-testid="stateErr"
                      className={`${
                        state.error != "" && state.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {state.error}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        zip.error == "" || !zip.touched ? "" : styles.errorField
                      }`}
                      id="zip"
                      aria-label="enter zip"
                      name="zip"
                      autoFocus
                      value={zip.value}
                      placeholder="Zip *"
                      onSelect={() => setZip(handleInput("touched", true, zip))}
                      onChange={(e) =>
                        setZip(handleInput("value", e.target.value, zip))
                      }
                      maxLength={5}
                      type={"text"}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="zipErr"
                      className={`${
                        zip.error != "" && zip.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {zip.error}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`${styles.inputGrey} ${
                        phone.error == "" || !phone.touched
                          ? ""
                          : styles.errorField
                      }`}
                      id="phone"
                      aria-label="enter phone"
                      name="phone"
                      type={"tel"}
                      value={phone.value}
                      placeholder="Phone *"
                      onSelect={() =>
                        setPhone(handleInput("touched", true, phone))
                      }
                      onChange={(e) =>
                        setPhone(handleInput("value", e.target.value, phone))
                      }
                      maxLength={10}
                      pattern="[0-9]+"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div
                      data-testid="phoneErr"
                      className={`${
                        phone.error != "" && phone.touched
                          ? styles.errorMessage
                          : styles.disable
                      }`}
                    >
                      {phone.error}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <SubmitButton
            outlined={false}
            name={"Previous"}
            submitFunction={prev}
            id={"register-myschool-prev-button"}
          />
          <SubmitButton
            outlined={false}
            isDisabled={isDisabled}
            name={"Create Account"}
            submitFunction={createAccount}
            id={"register-myschool-create-account-button"}
          />
          <div
            data-testid="serverErr"
            className={`${
              status != 200 && state.touched
                ? styles.errorMessage
                : styles.disable
            }`}
          >
            We're experiencing an issue, please try again later.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolBuilder;
