import React, { createContext } from 'react';
import propTypes from 'prop-types';

const ConfigContext: React.Context<any> = createContext({});

const defaultConfig = {
  env: process.env.REACT_APP_ENV || '',
  reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  reCaptchaV3SiteKey: process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || '',
  appOrigin: process.env.REACT_APP_ORIGIN || '',
  apiBase: process.env.REACT_APP_API_BASE || '',
  loginUrl: process.env.REACT_APP_LOGIN_URL || '',
  aemUrl: process.env.REACT_APP_AEM_URL || '',
  tso: {
    origin: process.env.REACT_APP_TSO_ORIGIN || '',
  },
  tsocheckout: {
    origin: process.env.REACT_APP_TSO_ORIGIN || '',
  },
  tsoprntquote: {
    origin: process.env.REACT_APP_TSO_ORIGIN || '',
  },
  sso: {
    origin: process.env.REACT_APP_SSO_ORIGIN || '',
  },
  ssocheckout: {
    origin: process.env.REACT_APP_SSO_ORIGIN || '',
  },
  ssocoreg: {
    origin: process.env.REACT_APP_SSO_ORIGIN || '',
  },
  ssocosignin: {
    origin: process.env.REACT_APP_SSO_ORIGIN || '',
  },
  ofesso: {
    origin: process.env.REACT_APP_SSO_ORIGIN || '',
  },
  beta: {
    origin: process.env.REACT_APP_BETA_ORIGIN || '',
  },
  pcs: {
    origin: process.env.REACT_APP_PCS_ORIGIN || '',
  },
  tcs: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  corphome: {
    origin: process.env.REACT_APP_CORP || '',
  },
  cptk: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  vms: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  ewallet: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  lah: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  classmags: {
    origin: process.env.REACT_APP_BASELINE_ORIGIN || '',
  },
  bw: {
    origin: process.env.REACT_APP_BW || '',
  },
  tcb: {
    origin: process.env.REACT_APP_TCB_ORIGIN || '',
  },
  face: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  lp: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  nys: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  cos: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  rif: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  ror: {
    origin: process.env.REACT_APP_PARTNER_ORIGIN || '',
  },
  clubs: {
    origin: process.env.REACT_APP_CLUBS_ORIGIN || '',
  },
  sdm: {
    origin: process.env.REACT_APP_SDM_ORIGIN || '',
  },
  myschl: {
    origin: process.env.REACT_APP_MYSCHL_ORIGIN || '',
  },
  usor: {
    origin: process.env.REACT_APP_USOR_ORIGIN || '',
  },
  bfaem: {
    origin: process.env.REACT_APP_BFAEM_ORIGIN || '',
  },
  cmplus: {
    origin: process.env.REACT_APP_CMPLUS_ORIGIN || '',
  },
  pportal: {
    origin: process.env.REACT_APP_PPORTAL || '',
  },
  os: {
    origin: process.env.REACT_APP_OS || '',
  },
  edu: {
    origin: process.env.REACT_APP_EDU || '',
  },
  storyvoice: {
    origin: process.env.REACT_APP_STORYVOICE || '',
  },
  scholasticstore: {
    origin: process.env.REACT_APP_SCHOLASTIC_STORE || '',
  },
  myschlapibase: {
    origin: process.env.REACT_APP_MYSCHL_API_BASE || '',
  },
  sdc: {
    origin: process.env.REACT_APP_SDC || '',
  },
  literacy: {
    origin: process.env.REACT_APP_LITERACY || '',
  }
};

const ConfigProvider = ({ value, children }: any) => 
  <ConfigContext.Provider value={value}>
    {children}
  </ConfigContext.Provider>;

ConfigProvider.propTypes = {
  value: propTypes.object,
  children: propTypes.node.isRequired
};

ConfigProvider.defaultProps = {
  value: defaultConfig
}

export { ConfigContext, ConfigProvider };