import { useContext } from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useRequestPasswordResetApi = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const requestPassword = async (email: string, skipEmail: false, ref: string) => {
    return await makeApiRequest(`${apiBase}/api/request-reset-password?ref=${ref}`, { "email": email, "skipEmail": skipEmail});
  };

  return [requestPassword];
};

export default useRequestPasswordResetApi;