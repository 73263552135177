import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { school } from "../../../interfaces/school";
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useRegisterApi from '../../../hooks/useRegisterApi';
import refProps from '../../../interfaces/refProps';
import styles from './ThankYou.module.scss';
import buildRegisterBody from "../../../utils/register-body-builder";


interface ThankYouProps { }

const ThankYou = (props: refProps) => {

  const location = useLocation<any>();
  const [firstName, setFirstName] = useState(location.state?.firstName);
  const [lastName, setLastName] = useState(location.state?.lastName);
  const [email, setEmail] = useState(location.state?.email);
  const [password, setPassword] = useState(location.state?.password);
  const [role, setRole] = useState(location.state?.role);
  const [grades, setGrades] = useState(location.state?.grades);
  const [position, setPosition] = useState(location.state?.position);
  const [school, setSchool] = useState<school>(location.state?. school);

  const [status, setStatus] = useState(200);
  
  const { referrer, chat, reg } = props;

  let history = useHistory();
  const [registerUser] = useRegisterApi();

  const register = async () => {
    if(school !=null){
      const { data, error } = await registerUser(
        buildRegisterBody(
          firstName,
          lastName,
          password,
          email,
          school.spsId,
          role,
          position,
          grades != null ? grades : []
        )
      );
      if (error != null) {
        setStatus(error.status);
        console.log(error);
      } else {
        setStatus(200);
        console.log(data);
        history.push({
          pathname: "/register/welcome.html",
          state: { user: data, username: email, password: password },
        });
      }
    }
  };

  return (
    <div className={styles.ThankYou} data-testid="ThankYou">
      <div className={styles.header}>Thank You</div>
      <div className={styles.myInfoContainer}>
        <div className={styles.warning}>
          Scholastic will need to verify your school before you can take advantage of the benefits we offer registered teachers. Once your school has been verified, you will be notified in your account alerts. This will take about seven days. Until then, please feel free to continue using our site.
        </div>
        <div className={styles.buttonContainer}>
          <SubmitButton outlined={false} name={'Ok'} submitFunction={register} id={"register-thankyou-ok-button"} />
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
