import React, { useState, useEffect } from "react";
import styles from "./MyKids.module.scss";
import { GRADES } from "../../../constants/grades";
import validate from "../../../utils/validate";
import useDebounce from "../../../hooks/useDebounce";
import Select from "react-select";
import close from '../../../styles/images/close.svg';
import {customStyles} from "../../../utils/select-custom-styles-role";

const MyKid = ({
  setButtonDisabled,
  child_id,
  updateChildren,
  children,
  deleteChildForm,
}) => {
  const firstNameDebouncer = useDebounce();
  const lastNameDebouncer = useDebounce();

  const [firstName, setFirstName] = useState({
    value: "",
    error: "",
    touched: false,
  });

  const [lastName, setLastName] = useState({
    value: "",
    error: "",
    touched: false,
  });

  const [grade, setGrade] = useState<string>("");

  const handleInput = (type: string, input: any, field: object) => {
    const newField: any = { ...field };
    newField[type] = input;
    return newField;
  };

  useEffect(() => {
    updateChildren(child_id, {
      firstName: firstName.value,
      lastName: lastName.value,
      grade: grade,
    });
  }, [firstName, grade, lastName]);

  useEffect(() => {
    const timeout = firstNameDebouncer(
      setFirstName.bind(
        MyKid,
        handleInput("error", validate(firstName.value, ["firstNameReq", "childNameMinReq", "unicodeCheck"]), firstName)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [firstName.value]);

  useEffect(() => {
    const timeout = lastNameDebouncer(
      setLastName.bind(
        MyKid,
        handleInput("error", validate(lastName.value, ["lastNameReq", "childNameMinReq", "unicodeCheck"]), lastName)
      ),
      300
    );
    return () => clearTimeout(timeout);
  }, [lastName.value]);

  const triggerButtonDisabled = () => {
    if (firstName.error === "" &&  lastName.error === "" && grade !== "") {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    triggerButtonDisabled();
  }, [firstName, lastName, grade,children]);

  return (
    <div className={styles.kidInfoContainer}>
      {child_id !== 0 &&
        <img
          className={styles.btnClose}
          alt="cancel btn"
          onClick={() => deleteChildForm(child_id)}
          src={close}
        />
      }
      <div>
        <input
          className={`${styles.inputGrey} ${firstName.error === "" || !firstName.touched ? "" : styles.errorField}`}
          id="first-name"
          aria-label="enter first name"
          name="firstName"
          value={firstName.value}
          placeholder="First Name"
          onSelect={() => setFirstName(handleInput("touched", true, firstName))}
          onChange={(e) => setFirstName(handleInput("value", e.target.value, firstName))}
          maxLength={30}
        />
        <div data-testid="firstNameErr" className={`${firstName.error !== "" && firstName.touched ? styles.errorMessage : styles.disable}`}>
          {firstName.error}
        </div>
      </div>
      <div>
        <input className={`${styles.inputGrey} ${lastName.error === "" || !lastName.touched ? "" : styles.errorField}`}
          id="last-name"
          aria-label="enter last name"
          name="lastName"
          value={lastName.value}
          placeholder="Last Name"
          onSelect={() => setLastName(handleInput("touched", true, lastName))}
          onChange={(e) =>
            setLastName(handleInput("value", e.target.value, lastName))
          }
          maxLength={30}
        />
        <div data-testid="lastNameErr" className={`${lastName.error !== "" && lastName.touched ? styles.errorMessage : styles.disable}`}>
          {lastName.error}
        </div>
      </div>
      <div>
        <Select options={GRADES} placeholder="Grade" styles={customStyles} onChange={(e: any) => setGrade(e?.value)} />
      </div>
    </div>
  );
};

export default MyKid;
