import {useContext} from 'react';
import { ConfigContext } from '../providers/ConfigContext';
import useMakeApiRequest from './useMakeApiRequest';

const useRegisterApi = () => {
  const { apiBase } = useContext(ConfigContext);
  const makeApiRequest = useMakeApiRequest('POST');

  const registerUser = async (body: any) => {
    return await makeApiRequest(`${apiBase}/api/register`, body);
  };

  return [registerUser];
};

export default useRegisterApi;